/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { useWindowSize } from "@utils";
import { InView } from "@designbycosmic/cosmic-react-common-components";
import gsap from "gsap";

const buildUrl = (url, params) =>
  `${url?.replace(
    process.env.GATSBY_CDN_BASE_URL,
    process.env.GATSBY_IMGIX_BASE_URL
  )}?${Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join("&")}`;

const Image = ({
  url,
  image,
  height,
  width,
  params: ixParams,
  objectFit,
  focalPoint,
  caption,
  transparent,
  alt,
  aspectratio: ratio,
  backgroundColor,
  eager,
  resolution,
  fill,
  widthAdjust: _widthAdjust,
  preserveAspectRatio,
  className,
  hero,
}) => {
  // if (!image) return null;

  const figure = useRef();
  const picture = useRef();
  const { innerWidth: windowSize } = useWindowSize();
  const [inView, setInView] = useState(false);
  const [loaded, setLoaded] = useState(eager);
  const [widthAdjust, setWidthAdjust] = useState(_widthAdjust);
  const [sources, setSources] = useState();

  // const { url, height, width, fpx, fpy, skipBuild } = image || {};
  const pr =
    typeof window !== "undefined" && !eager && !resolution
      ? window.devicePixelRatio
      : resolution;
  const arm = ratio ? ratio[1] / ratio[0] : 0;
  const ar = preserveAspectRatio || arm ? arm || height / width : 0;

  const defaultParams = {
    auto: eager ? "" : "format",
    fit: "max",
    q: pr > 1.5 || width > 1000 ? 50 : 70,
    crop: "faces,edges,entropy",
    w: 360, // from state
    h: parseInt(ar ? 360 : 360 * ar, 10), //  from state
    dpr: pr > 1.75 ? 1.75 : pr,
  };

  if (focalPoint) {
    // eslint-disable-next-line prefer-destructuring
    defaultParams["fp-x"] = focalPoint[0];
    // eslint-disable-next-line prefer-destructuring
    defaultParams["fp-y"] = focalPoint[1];
    defaultParams.fit = "crop";
    defaultParams.crop = "focalpoint";
  }

  const finalParams = { ...defaultParams, ...ixParams };

  const baseUrl = buildUrl(url, {
    ...finalParams,
    w: parseInt(400 * widthAdjust, 10),
    h: parseInt(arm ? arm * 400 * widthAdjust : 400 * widthAdjust, 10),
  });

  useEffect(() => {
    if (figure.current) {
      setWidthAdjust(
        (figure.current.clientWidth / windowSize).toFixed(1) || 0.1
      );
    }
  }, [figure.current, windowSize]);

  useEffect(() => {
    setSources(
      [400, 500, 850, 1024, 1360, 1750, 2000].map(size => ({
        url: buildUrl(url, {
          ...finalParams,
          w: parseInt(size * widthAdjust, 10) || size,
          h:
            parseInt(arm ? arm * size * widthAdjust : size * widthAdjust, 10) ||
            size,
          q: size > 1000 ? 50 : 80,
          fit: size <= 500 && hero ? "crop" : finalParams.fit,
          crop: size <= 500 && hero ? "faces" : finalParams.crop,
        }),
        size,
      }))
    );
  }, [widthAdjust, url]);

  // fade in the image onLoad
  useEffect(() => {
    if (loaded && inView && !eager) {
      setTimeout(() => {
        gsap.fromTo(
          picture.current,
          {
            opacity: 0,
          },
          {
            display: "block",
            opacity: 1,
            duration: 0.3,
            ease: "power1.in",
            clearProps: "transform",
            onComplete: () => {
              if (picture.current) {
                picture.current.style.transform = null;
              }
            },
          }
        );
      }, 0);
    }
  }, [loaded, inView]);

  return (
    <InView
      observerOptions={{
        threshold: 0.01,
      }}
      className={`w-full ${arm ? "h-auto" : "h-full"}`}
      onEnter={() => {
        setInView(true);
      }}
      unobserveAfterEntry
    >
      {eager && (
        <Helmet>
          <link rel="preload" as="image" href={baseUrl} />
        </Helmet>
      )}
      <figure
        ref={figure}
        className={classNames(
          className,
          "w-full flex flex-col overflow-hidden",
          {
            "absolute inset-0": fill,
            relative: !fill,
            "transition duration-200": !eager,
          }
        )}
      >
        <div
          className={classNames({ "absolute inset-0": fill })}
          style={{
            backgroundColor,
            paddingTop: 0,
            paddingBottom: fill
              ? 0
              : `${((1 / ratio || height / width) * 100).toFixed(2)}%`,
          }}
        >
          <picture
            ref={picture}
            className={classNames({
              "w-full h-full": true,
              "transition duration-200 opacity-0": !eager,
            })}
          >
            {/* load srcset */}
            {(inView || eager) && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {sources?.map((s, i) => (
                  <source
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    srcSet={s.url}
                    media={`(max-width: ${sources?.[i + 1]?.size || 4000}px)`}
                  />
                ))}
              </>
            )}
            {/* todo: fix alt tag fallback */}
            <img
              width="100%"
              height="100%"
              onLoad={!eager ? () => setLoaded(true) : null}
              src={inView || eager ? baseUrl : null}
              alt={alt || "image"}
              className={classNames("absolute w-full h-full inset-0", {
                "object-cover": objectFit === "cover",
                "object-contain": objectFit === "contain",
              })}
            />
          </picture>
        </div>
        {caption && <figcaption>{caption}</figcaption>}
      </figure>
    </InView>
  );
};

Image.defaultProps = {
  objectFit: "cover",
  eager: false,
  widthAdjust: 1,
};

export default Image;
