import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query GdprQuery {
    craft {
      globalSets(handle: "gdpr", site: "*") {
        language
        ... on Craft_gdpr_GlobalSet {
          intro
          contactUsText
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const langs = {};

  craft.globalSets.forEach(global => {
    const { intro: copy, contactUsText: buttonText } = global;
    langs[global.language] = {
      copy,
      buttonText,
    };
  });
  return langs;
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
