import React, { useState } from "react";
import { Video } from "@atoms";
import { InView } from "@designbycosmic/cosmic-react-common-components";

const CbCta = ({ url }) => {
  const [inView, setInView] = useState(false);
  return (
    <section className="w-full relative">
      <InView
        observerOptions={{
          threshold: 0.1,
        }}
        onEnter={() => {
          setInView(true);
        }}
        unobserveAfterEntry
      >
        <Video url={url} play={inView} muted loop />
      </InView>
    </section>
  );
};

CbCta.defaultProps = {};

CbCta.propTypes = {};

export default CbCta;
