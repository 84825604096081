import React from "react";
import { Icon, Text } from "@atoms";
import { AppLink } from "@base";
import { t } from "@utils";
import { useAppState } from "@state/state";

const Share = ({ url: _url, hideShare }) => {
  const [{ translation }] = useAppState();
  const { lang } = translation;
  const url = encodeURIComponent(_url);
  const buttonClasses =
    "flex items-center p-1 justify-center w-7 h-7 font-normal group leading-none transition duration-200 text-blue fill-blue";
  return (
    <div className="flex items-center space-x-5 mb-8">
      {!hideShare && (
        <Text variant="lg" className="uppercase font-bold">
          {t("Share", lang)}
        </Text>
      )}
      <AppLink
        to={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        className={buttonClasses}
      >
        <Icon name="facebookAlt" className="w-full h-full" fitHeight />
      </AppLink>
      <AppLink
        to={`https://twitter.com/intent/tweet?url=${url}`}
        className={buttonClasses}
      >
        <Icon name="twitter" className="w-full h-full" />
      </AppLink>
      <AppLink
        to={`http://www.linkedin.com/shareArticle?mini=true&url=${url}`}
        className={buttonClasses}
      >
        <Icon name="linkedinAlt" className="w-full h-full" fitHeight />
      </AppLink>
    </div>
  );
};

Share.defaultProps = {
  hideShare: false,
};

Share.propTypes = {};

export default Share;
