export default {
  Name: "Имя",
  ID: "Я БЫ",
  Color: "Цвет",
  Price: "Цена",
  "Learn More": "Узнать больше",
  "Play video": "Проиграть видео",
  "View the transmitters for": "Просмотреть передатчики для",
  "Select one": "Выберите один",
  "All Resources": "Все ресурсы",
  Showing: "Показ",
  "Job Title": "Название работы",
  Company: "Компания",
  Email: "Эл. адрес",
  "You have already been added to the newsletter list.":
    "Вы уже добавлены в список рассылки новостей.",
  "Please fill out all required fields":
    "Пожалуйста, заполните все обязательные поля",
  Submit: "Представлять на рассмотрение",
  "PDF Downloads": "Загрузки в формате PDF",
  Videos: "Ролики",
  "Related Articles": "Статьи по Теме",
  Share: "Делиться",
  "Load More Articles": "Загрузить больше статей",
  "Read Article": "Читать статью",
  "Next Article": "Следующая статья",
  "Next Event": "Следующее событие",
  Explore: "Проводить исследования",
  "Read More": "Подробнее",
  Includes: "Включая",
  "Search articles": "Поиск статей",
  "Play Video": "Воспроизвести видео",
};
