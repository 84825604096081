import React from "react";
import classNames from "classnames";
import { Text, Container } from "@atoms";

const SideHeadline = ({
  heading,
  subheading,
  color,
  className: _className,
}) => {
  return (
    <Container
      variant="md"
      className={classNames(_className, {
        "text-white": color === "white",
        "text-black": color === "black",
      })}
    >
      <div className="block md:grid grid-cols-8 xl:grid-cols-12 gap-8">
        <div className="col-span-3 xl:col-span-4">
          <Text variant="h1">{heading}</Text>
        </div>
        <div className="col-span-5 xl:col-span-8 mt-6">
          <Text variant="lg">{subheading}</Text>
        </div>
      </div>
    </Container>
  );
};

SideHeadline.defaultProps = {};

export default SideHeadline;
