import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query FooterQuery {
    craft {
      footerNav: globalSets(handle: "footerNav", site: "*") {
        language
        ... on Craft_footerNav_GlobalSet {
          nav: nestedLinkList {
            ... on Craft_nestedLinkList_link_BlockType {
              type: typeHandle
              link: linkObject {
                url
                text
              }
            }
            ... on Craft_nestedLinkList_links_BlockType {
              type: typeHandle
              listHeader {
                url
                text
              }
              list {
                ... on Craft_list_BlockType {
                  link: linkObject {
                    url
                    text
                  }
                }
              }
            }
          }
        }
      }
      footer: globalSets(handle: "footer", site: "*") {
        language
        ... on Craft_footer_GlobalSet {
          footerCtas {
            ... on Craft_footerCtas_cta_BlockType {
              ctaItem {
                url
                text
              }
            }
          }
          social {
            ... on Craft_social_socialNetwork_BlockType {
              socialLink {
                url
                ariaLabel
              }
              icon
            }
          }
          footerSublinks {
            ... on Craft_footerSublinks_sublink_BlockType {
              linkIt {
                url
                text
              }
            }
          }
          address: footerAddress
          email {
            url
            text
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const langs = {};

  craft.footerNav.forEach(global => {
    const { nav } = global;
    langs[global.language] = { nav };
  });

  craft.footer.forEach(global => {
    const { footerCtas, social, footerSublinks, address, email } = global;
    langs[global.language] = {
      ...langs[global.language],
      footerCtas,
      social,
      footerSublinks,
      address,
      email,
    };
  });

  return langs;
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
