import React from "react";
import { AppLink } from "@base";
import { Text } from "@atoms";

const Listings = ({
  title,
  location,
  locationCountry,
  detailLink,
  descriptionDefault,
}) => {
  const flag = {
    "United States": "america.jpg",
    China: "china.jpg",
    Germany: "german.jpg",
    Australia: "australia.jpg",
    India: "india.jpg",
    Russia: "russia.jpg",
    Philippines: "philippines.png",
  };

  return (
    <AppLink
      to={detailLink}
      className="text-left transition-all duration-200 block bg-white border-t-[6px] border-transparent hover:border-blue p-4 pt-[calc(1rem-6px)]"
    >
      <Text variant="lg">{title}</Text>
      <Text variant="body" className="text-silver">
        {descriptionDefault}
      </Text>
      <div className="flex mt-2 items-center">
        {flag[locationCountry] && (
          <img
            src={`/images/flags/${flag[locationCountry]}`}
            className="mr-1 w-7"
            alt=""
            loading="lazy"
            width="30"
            height="20"
          />
        )}

        <Text variant="sm" className="text-silver">
          {location}
        </Text>
      </div>
    </AppLink>
  );
};
Listings.defaultProps = {};

export default Listings;
