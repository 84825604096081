import React from "react";
import classNames from "classnames";
import { Container, Text, Button } from "@atoms";

const CbCta = ({ heading, summary, link, link2, first, last, dealer }) => {
  if (dealer) {
    return (
      <section
        className={classNames("text-center py-12 md:py-16 bg-light-gray my-2")}
      >
        <Container variant="sm">
          <div className="max-w-2xl mx-auto">
            <Text variant="h3" className="lg:text-3xl mb-4">
              {heading}
            </Text>
            <Text variant="body" className="my-4">
              {summary}
            </Text>
            {link?.url && (
              <div className="flex justify-center">
                <Button size="lg" arrow to={link?.url}>
                  <Text variant="sm">{link?.text}</Text>
                </Button>
              </div>
            )}
          </div>
        </Container>
      </section>
    );
  }

  return (
    <section
      className={classNames(
        "text-center py-12 md:py-16",
        { "mt-16": first },
        { "mb-16": last },
        { "my-16": !first && !last }
      )}
    >
      <Container variant="sm">
        <div className="max-w-2xl mx-auto">
          <Text variant="h3" className="mb-8">
            {heading}
          </Text>
          <Text variant="body" className="my-8" richText={false}>
            {summary}
          </Text>
          <div className="flex flex-col items-center md:flex md:flex-row space-y-4 md:space-y-0 space-x-0 md:space-x-4 justify-center">
            {link?.url && (
              <Button size="lg" to={link?.url}>
                <Text>{link?.text}</Text>
              </Button>
            )}
            {link2?.url && (
              <Button size="lg" to={link2?.url}>
                <Text>{link2?.text}</Text>
              </Button>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

CbCta.defaultProps = {};

CbCta.propTypes = {};

export default CbCta;
