import React, { useState } from "react";
import classNames from "classnames";
import { Text, Image, Container, Icon } from "@atoms";
import Carousel from "./Carousel";

const ReadMore = ({
  label,
  heading,
  content,
  images,
  color,
  padding,
  mobileCollapse,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <section
      className={classNames({
        "bg-white": color === "white",
        "bg-light-gray": color === "gray",
        "py-16": padding,
      })}
    >
      <Container variant="xl" className="py-6 md:py-16 px-4">
        <div className="flex flex-col md:grid grid-cols-2 gap-8 md:gap-1 justify-items-stretch px-0 md:px-8">
          <div className="col-span-1 mr-auto w-full max-w-md px-0 sm:pr-4">
            {images?.length === 1 && (
              <div className="w-full pb-64 sm:pb-96 relative">
                <Image
                  {...images[0]}
                  className="rounded-lg overflow-hidden"
                  fill
                />
              </div>
            )}
            {images?.length > 1 && (
              <Carousel showIndicators>
                {images?.map((image, i) => {
                  return (
                    <div className="w-full pb-64 sm:pb-96 relative">
                      <Image
                        {...image}
                        className="rounded-lg overflow-hidden"
                        key={`read-more-image--${i + 1}`}
                        fill
                      />
                    </div>
                  );
                })}
              </Carousel>
            )}
          </div>
          <div className="col-span-1">
            <div className="relative col-span-1 ml-auto place-items-stretch">
              <div className="relative mb-6 w-full">
                <div className="ml-0 md:ml-auto flex flex-col max-w-none md:max-w-md px-0 md:px-4">
                  <Text variant="sm" className="text-blue mb-4 font-semibold">
                    {label}
                  </Text>
                  <Text variant="h2">{heading}</Text>
                </div>

                <div className="h-full w-1 bg-blue absolute top-0 -left-4 md:-left-1" />
              </div>
              <div
                className={classNames(
                  "relative overflow-hidden pb-4 max-w-none md:max-w-md ml-auto px-0 md:px-4 max-h-none",
                  {
                    "md:max-h-64": !open,
                  }
                )}
                aria-expanded={open}
              >
                <Text variant="md">{content}</Text>
                <div
                  className={classNames(
                    "absolute w-full h-40 bg-gradient-to-t bottom-0 inset-x-0 z-0",
                    { "from-white": color === "white" },
                    { "from-light-gray": color === "gray" },
                    { hidden: open },
                    { "hidden md:block": !open }
                  )}
                />
              </div>
              <button
                type="button"
                className="absolute z-50 right-0 -bottom-5 z-50 p-5 py-3 hidden md:block"
                onClick={() => setOpen(!open)}
              >
                <Icon
                  name="arrowRight"
                  className={classNames(
                    "text-blue fill-blue fill-blue w-3 transition duration-200",
                    {
                      "rotate-90": !open,
                      "-rotate-90": open,
                    }
                  )}
                />
              </button>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

ReadMore.defaultProps = {
  padding: true,
};

export default ReadMore;
