import React, { useState, useEffect, useRef } from "react";
import { Text, Container } from "@atoms";
import { useWindowSize } from "@utils";
import classNames from "classnames";
import gsap from "gsap";

const Value = ({ title, description, index, highlightState }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [highlight, setHighlight] = highlightState || useState(0);
  const { innerWidth: windowSize } = useWindowSize();
  const breakpoint = 850;

  const text = useRef();

  useEffect(() => {
    if (highlight === index) {
      gsap.to(text.current, { height: "auto", duration: 0.2 });
    } else {
      gsap.to(text.current, { height: 0, duration: 0.2 });
    }
  }, [highlight]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className="w-full mb-4 cursor-default"
      onMouseEnter={() => {
        if (windowSize >= breakpoint) {
          setHighlight(index);
        }
      }}
      onClick={() => {
        if (windowSize < breakpoint) {
          setHighlight(index);
        }
      }}
    >
      <div className="flex flex-row w-full justify-between items-center">
        <Text
          className={classNames(
            "text-2.5xl md:text-3.5xl mb-2 transition duration-200 font-heading font-semibold",
            {
              "text-blue": highlight === index,
            }
          )}
        >
          {title}
        </Text>
      </div>
      <div ref={text} className="h-0 overflow-hidden">
        <Text
          variant="sm"
          className={classNames(
            "font-heading mx-auto font-semibold leading-tight text-silver"
          )}
        >
          {description}
        </Text>
      </div>
    </div>
  );
};

const CbValues = ({ heading, subheading, values }) => {
  const [highlight, setHighlight] = useState(0);

  return (
    <section className="py-16">
      <Container
        variant="xl"
        className="grid gap-8 md:gap-4 grid-cols-1 md:grid-cols-2"
      >
        <div className="px-0 md:px-8">
          <Text variant="body" className="mb-4 text-dark-gray max-w-2xl">
            {subheading}
          </Text>
          <Text variant="h2">{heading}</Text>
        </div>
        {values?.length && (
          <div className="px-0 md:px-8 mt-0 md:mt-10">
            {values?.map((value, i) => {
              return (
                <Value
                  {...value}
                  index={i}
                  highlightState={[highlight, setHighlight]}
                />
              );
            })}
          </div>
        )}
      </Container>
    </section>
  );
};

CbValues.defaultProps = {};

export default CbValues;
