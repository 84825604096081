import React, { useState } from "react";
import classNames from "classnames";
import { FormContainer, Field, Button, Text } from "@atoms";
import { useNewsletterData } from "@staticQueries";
import axios from "axios";
import { t } from "@utils";
import { useAppState } from "@state/state";

const Newsletter = () => {
  const [{ translation }] = useAppState();
  const { lang } = translation;
  const data = useNewsletterData();
  const { heading, subHeading, thankYouMessage } = data[lang || "en"];
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState([]);
  const hasWindow = typeof window !== "undefined";

  const formFields = [
    {
      label: t("Name", lang),
      name: "name",
      required: true,
      type: "text",
      cols: 2,
    },
    {
      label: t("Job Title", lang),
      name: "jobTitle",
      required: true,
      type: "text",
      cols: 2,
    },
    {
      label: t("Company", lang),
      name: "company",
      required: true,
      type: "text",
      cols: 2,
    },
    {
      label: t("Email", lang),
      name: "email",
      required: true,
      type: "text",
      cols: 2,
    },
  ];
  const requiredFields = formFields
    ?.filter(_f => _f?.required)
    ?.map(_f => _f.name)
    ?.sort();

  // const encode = data => {
  //   // Swappiong to formData to seeing if that'll actually store the image
  //   // in Netlify's forms
  //   const formatData = new FormData();
  //   Object.keys(data).forEach(key => {
  //     if (key === "resume") {
  //       for (const file of data[key]) {
  //         formatData.append(key, file, file.name);
  //       }
  //     } else {
  //       formatData.append(key, data[key]);
  //     }
  //   });
  //   return formatData;
  // };

  const onSubmit = e => {
    e.preventDefault();
    // const { name, jobTitle, company, email } = formData || {};
    const formKeys = Object.keys(formData)?.sort();
    const hasRequiredFields =
      formKeys?.length > 0 &&
      requiredFields.every((value, index) => {
        return formKeys?.includes(value);
      });
    const pageUrl = hasWindow ? new URL(window.location) : null;

    if (hasRequiredFields && !success) {
      setInProcess(true);

      const send = JSON.stringify({ ...formData, pageUrl: pageUrl?.pathname });
      axios
        .post(`/.netlify/functions/mailchimp`, send, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
        .then(res => {
          setInProcess(false);

          if (!res.data.error) {
            setSuccess(true);
            setError(null);
            return;
          }

          setError(
            t("You have already been added to the newsletter list.", lang)
          );
        })
        .catch(err => {
          setInProcess(false);
          setError(
            t("You have already been added to the newsletter list.", lang)
          );
          // eslint-disable-next-line no-console
          console.log(err);
        });
    } else {
      const missingFields = requiredFields
        ?.filter(x => !formKeys.includes(x))
        ?.concat(formKeys.filter(x => !requiredFields.includes(x)));

      setFormError(missingFields);
      setError(t("Please fill out all required fields", lang));
    }
  };

  return (
    <div className="border border-light-gray p-6 my-8 lg:my-14">
      <Text variant="h4">{heading}</Text>
      <Text variant="body" className="my-6 text-dark-gray">
        {subHeading}
      </Text>
      {error && (
        <Text variant="body" className="my-6 text-red">
          {error}
        </Text>
      )}
      {success && (
        <Text variant="body" className="my-6 text-dark-gray">
          {thankYouMessage}
        </Text>
      )}
      {!success && (
        <FormContainer
          onSubmit={onSubmit}
          className={classNames("", { "opacity-25": inProcess })}
        >
          {formFields?.map((field, i) => {
            const { name } = field;

            return (
              <Field
                {...field}
                onChange={e =>
                  setFormData({ ...formData, [name]: e.target.value })
                }
                errorState={[formError, setFormError]}
              />
            );
          })}
          <div className="col-span-full">
            <Button type="submit" size="lg">
              <Text>{t("Submit", lang)}</Text>
            </Button>
          </div>
        </FormContainer>
      )}
    </div>
  );
};

Newsletter.defaultProps = {};

Newsletter.propTypes = {};

export default Newsletter;
