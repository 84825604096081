import React, { useEffect, useState, useRef } from "react";
import { Text } from "@atoms";
import languages from "@translations/languages";
import { useAppState } from "@state/state";
import { isTouchDevice } from "@utils";
import gsap from "gsap";

const LocaleSwitcher = () => {
  const [{ translation }, dispatch] = useAppState();
  const [activeLocale, setActiveLocale] = useState(null);
  const { lang: currentLang } = translation;

  const tl = useRef();
  const dropdownContainer = useRef();

  const [expanded, setExpanded] = useState();

  useEffect(() => {
    if (dropdownContainer.current) {
      tl.current = gsap
        .timeline({ paused: true })
        .to(dropdownContainer.current, {
          y: "-1rem",
          pointerEvents: "auto",
          opacity: 1,
          duration: 0.35,
          ease: "power2.inOut",
          onComplete: () => {
            setExpanded(true);
          },
          onReverseComplete: () => {
            setExpanded(false);
          },
        });
    }
  }, []);

  useEffect(() => {
    if (expanded) {
      tl.current.play();
    } else {
      tl.current.reverse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  useEffect(() => {
    if (activeLocale) {
      dispatch({
        type: "setLang",
        lang: activeLocale.id,
      });
    } else if (currentLang) {
      setActiveLocale(languages[currentLang]);
    }
  }, [activeLocale, currentLang]);

  return (
    <div
      className="block w-8 relative group z-50"
      onMouseEnter={() => {
        if (!isTouchDevice) setExpanded(true);
      }}
      onMouseLeave={() => setExpanded(false)}
    >
      {activeLocale && (
        <button type="button" onClick={() => setExpanded(true)}>
          <Text
            variant="xs"
            className="uppercase h-4 cursor-default mt-0.5 cursor-pointer"
          >
            {`${activeLocale.id}`}
          </Text>
        </button>
      )}
      <div
        ref={dropdownContainer}
        aria-expanded={expanded}
        className="absolute top-[100%] right-0 w-32 pointer-events-none bg-white opacity-0"
      >
        <ul className="grid grid-cols-1 transition duration-200 text-xs uppercase">
          {Object.values(languages).map(lang => (
            <li key={lang.id}>
              <button
                type="button"
                className="flex text-left w-full py-2 px-3 transition duration-200 hover:bg-light-gray"
                onClick={() => {
                  setActiveLocale(lang);
                  setExpanded(false);
                }}
              >{`${lang.id} - ${lang.name}`}</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

LocaleSwitcher.defaultProps = {};

LocaleSwitcher.propTypes = {};

export default LocaleSwitcher;
