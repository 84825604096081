import React, { useState } from "react";
import classNames from "classnames";
import { Text, Container, Column } from "@atoms";

const CbColumns = ({
  heading,
  subheading,
  columns,
  noHorizontalScroll,
  lessWhitespace,
  grayBackground,
  center,
  spacingBottom,
}) => {
  const [openTable, setOpenTable] = useState(null);

  return (
    <section
      className={classNames(
        "mt-2 text-dark-gray",
        { "py-16": !lessWhitespace },
        { "pb-16": lessWhitespace },
        { "bg-light-gray": grayBackground }
      )}
    >
      <Container variant="xl">
        <div className="text-center mb-12">
          <Text variant="h2">{heading}</Text>
          <Text variant="body" className="mt-6 text-dark-gray">
            {subheading}
          </Text>
        </div>
        {columns?.length > 0 && (
          <div
            className={classNames(
              "flex items-start",
              {
                "flex-nowrap xs:flex-wrap lg:flex-nowrap overflow-x-auto":
                  !noHorizontalScroll,
              },
              { "flex-wrap": noHorizontalScroll },
              { "justify-center": center }
            )}
          >
            {columns?.map((column, i) => {
              return (
                <Column
                  {...column}
                  fullWidthMobile={noHorizontalScroll}
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  index={i}
                  model={[openTable, setOpenTable]}
                  spacingBottom={spacingBottom}
                />
              );
            })}
          </div>
        )}
      </Container>
    </section>
  );
};

CbColumns.defaultProps = {};

CbColumns.propTypes = {};

export default CbColumns;
