import React, { useState } from "react";
import classNames from "classnames";
import { Text, Icon } from "@atoms";

const Field = ({
  label,
  name,
  required,
  placeholder,
  type,
  textarea,
  hideLabel,
  onChange,
  cols,
  light,
  singleLine,
  errorState,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [error] = errorState || useState([]);

  return (
    <div
      className={classNames(
        "relative",
        { "col-span-full md:col-span-1": cols === 1 },
        { "col-span-full": cols === 2 },
        { "flex items-center": singleLine }
      )}
    >
      <label
        htmlFor={name}
        className={classNames(
          "inline-flex",
          { "mb-3": type || textarea },
          { "sr-only": hideLabel },
          { "mx-4 cursor-pointer relative": type === "checkbox" },
          { "w-max pr-4 !mb-0": singleLine },
          { "text-red": error?.includes(name) }
        )}
      >
        <Text variant="sm">{required ? `${label}*` : label}</Text>
      </label>
      {!textarea && type !== "checkbox" && type !== "file" && (
        <input
          type={type}
          name={name}
          id={name}
          className={classNames(
            "p-2 border w-full",
            { "bg-light-gray": !light },
            { "bg-white": light },
            { "border-red": error?.includes(name) }
          )}
          placeholder={placeholder}
          onChange={onChange}
        />
      )}
      {!textarea && type === "checkbox" && (
        <div className="relative order-first self-center">
          <input
            type={type}
            name={name}
            id={name}
            className="text-white p-2 appearance-none w-6 h-6 checked:bg-white mr-4 border-2 rounded-none cursor-pointer"
            placeholder={placeholder}
            onChange={onChange}
            // accept is not defined
            // accept={accept}
          />
          <Icon
            name="check"
            className="text-black w-4 h-4 absolute z-0 inset-1 pointer-events-none"
          />
        </div>
      )}
      {!textarea && type === "file" && (
        <>
          <button
            type="button"
            className="cursor-pointer relative tracking-wider inline-flex justify-center  items-center z-0 opacity-100 hover:opacity-50 text-white bg-blue group leading-tighter transition duration-200 px-8 py-5 text-base"
          >
            <Text>{selectedFile || "Choose file(s)"}</Text>
            <input
              type={type}
              name={name}
              id={name}
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
              placeholder={placeholder}
              onChange={e => {
                const fileNames =
                  e?.target?.files?.length > 1
                    ? `Multiple files selected`
                    : e?.target?.files?.[0]?.name;

                setSelectedFile(fileNames);
              }}
              multiple="multiple"
              accept="image/*"
            />
          </button>
          <Text variant="sm" className="text-dark-grey mx-4">
            limit 20MB
          </Text>
        </>
      )}
      {textarea && (
        <textarea
          name={name}
          id={name}
          className="!resize-y p-2 border w-full text-sm min-h-28"
          placeholder={placeholder}
          onChange={onChange}
        />
      )}
    </div>
  );
};

Field.defaultProps = {
  cols: 1,
};
Field.propTypes = {};

export default Field;
