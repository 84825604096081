import React, { useState } from "react";
import classNames from "classnames";
import { Text, Icon, Listings } from "@atoms";

const Departments = ({ name, jobs, descriptionDefault, scrollText }) => {
  const [openJobs, setOpenJobs] = useState(false);

  if (jobs?.length) {
    return (
      <div className="flex flex-col space-y-4 mb-4">
        <div>
          <Text
            variant="sm"
            className="font-heading font-semibold text-blue uppercase hidden md:block"
          >
            {name}
          </Text>
          <button
            type="button"
            className="flex justify-between w-full md:hidden pr-2"
            onClick={() => setOpenJobs(!openJobs)}
          >
            <Text
              variant="sm"
              className="font-heading font-semibold text-blue uppercase text-left"
            >
              {name}
            </Text>

            <Icon
              name="arrowRight"
              className={classNames(
                "text-blue fill-blue w-3 transition duration-300",
                {
                  "rotate-90": !openJobs,
                  "-rotate-90": openJobs,
                }
              )}
            />
          </button>
        </div>
        <div
          className={classNames("w-full flex-col space-y-4", {
            "hidden md:flex ": !openJobs,
            "flex md:flex": openJobs,
          })}
        >
          {jobs?.map((job, i) => {
            return (
              <Listings {...job} descriptionDefault={descriptionDefault} />
            );
          })}
        </div>
      </div>
    );
  }
  return null;
};
Departments.defaultProps = {};

export default Departments;
