// spanish translations
export default {
  Name: "姓名",
  ID: "ID",
  Color: "颜色",
  Price: "价钱",
  "Learn More": "了解更多",
  "Play video": "播放视频",
  "View the transmitters for": "查看发射器",
  "Select one": "选择一个",
  "All Resources": "所有资源",
  Showing: "显示",
  "Job Title": "职称",
  Company: "公司",
  Email: "电子邮件",
  "You have already been added to the newsletter list.":
    "您已被添加到时事通讯列表中。",
  "Please fill out all required fields": "请填写所有必填字段",
  Submit: "提交",
  "PDF Downloads": "PDF下载",
  Videos: "视频",
  "Related Articles": "相关文章",
  Share: "分享",
  "Load More Articles": "加载更多文章",
  "Read Article": "阅读文章",
  "Next Article": "下一篇",
  "Next Event": "下一个事件",
  Explore: "探索",
  "Read More": "阅读更多",
  Includes: "包括",
  "Search articles": "搜索文章",
  "Play Video": "播放视频",
};
