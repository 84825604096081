import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import gsap from "gsap";
import { convertToMoney } from "@utils";

const Table = ({ headers, content, open, index: _i }) => {
  const tableContainer = useRef();
  // First three are special and need it's own
  // special width
  const firstThreeCols = _i < 3;
  // Set width row it lives on
  const firstCol = (_i + 1) % 4 === 0;
  const secondCol = (_i + 1) % 4 === 1;
  const thirdCol = (_i + 1) % 4 === 2;
  const lastCol = (_i + 1) % 4 === 3;

  useEffect(() => {
    if (open) {
      gsap.to(tableContainer?.current, {
        height: "auto",
        duration: 0.3,
        ease: "power3.inOut",
      });
    } else {
      gsap.to(tableContainer?.current, {
        height: 0,
        duration: 0.3,
        ease: "power3.inOut",
      });
    }
  }, [open]);

  return (
    <div
      ref={tableContainer}
      className={classNames(
        "overflow-x-auto relative mt-8 z-20 w-full xs:w-[calc(200%+1rem)] left-0",
        { "h-auto": open },
        { "h-0": !open },
        { "md:w-[calc(300%+8rem)]": firstThreeCols },
        {
          "xs:left-[calc((100%+2rem)*-1)] md:left-[calc((100%+4rem)*-1)]":
            firstThreeCols && _i === 1,
        },
        { "left-0 md:left-[calc((200%+8rem)*-1)]": firstThreeCols && _i === 2 },
        { "md:w-[calc(400%+12rem)]": !firstThreeCols },
        { "xs:left-[calc((100%+2rem)*-1)]": (_i + 1) % 2 === 0 },
        { "md:left-0": (_i + 1) % 2 === 0 && firstCol },
        { "md:left-[calc((100%+4rem)*-1)]": !firstThreeCols && secondCol },
        { "md:left-[calc((200%+8rem)*-1)]": !firstThreeCols && thirdCol },
        { "md:left-[calc((300%+12rem)*-1)]": !firstThreeCols && lastCol }
      )}
    >
      <table className="text-dark-gray bg-light-gray w-full text-left border-collapse overflow-x-auto">
        <thead className="border-b border-light-silver">
          <tr>
            {headers?.map((header, i) => {
              return (
                <th
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className="p-4 font-heading font-bold w-1/4"
                  colSpan="1"
                >
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {content?.map((row, i) => {
            return (
              <tr
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className={classNames("border-t border-light-silver", {
                  "bg-near-white": i % 2 === 0,
                })}
              >
                <td className="p-4 py-5 text-sm" colSpan="1">
                  {row?.title}
                </td>
                <td className="p-4 py-5 text-sm" colSpan="1">
                  {row?.productId}
                </td>
                <td className="p-4 py-5 text-sm" colSpan="1">
                  <span
                    style={{ backgroundColor: row?.colorHex }}
                    className={classNames(
                      "p-1 px-2 text-sm whitespace-nowrap",
                      { "text-dark-gray": row?.colorNameMakeDark },
                      { "text-white": !row?.colorNameMakeDark }
                    )}
                  >
                    {row?.colorName}
                  </span>
                </td>
                <td className="p-4 py-5 text-sm" colSpan="1">
                  {convertToMoney(row?.price)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

Table.defaultProps = {};

Table.propTypes = {};

export default Table;
