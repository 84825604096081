import React from "react";
import classNames from "classnames";
import { Container, Text } from "@atoms";

const CbIntro = ({ copy, noBottomPadding }) => {
  return (
    <section
      className={classNames(
        "",
        { "pt-16": noBottomPadding },
        { "py-16": !noBottomPadding }
      )}
    >
      <Container variant="sm">
        <Text variant="2xl" className="max-w-2xl mx-auto">
          {copy}
        </Text>
      </Container>
    </section>
  );
};

CbIntro.defaultProps = {};

CbIntro.propTypes = {};

export default CbIntro;
