import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Text, Image } from "@atoms";
import { AppLink } from "@base";

const CareerCard = ({
  label,
  location,
  image,
  countryCode,
  highlightedState,
  activeState,
  idx,
  title,
  description,
  other,
  url,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [, setHighlighted] = highlightedState || useState("US");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [currentSlide, setCurrentSlide] = activeState || useState(0);

  useEffect(() => {
    if (currentSlide === idx) {
      setHighlighted(countryCode);
    }
  }, [currentSlide]);

  if (countryCode && location && idx >= 0) {
    return (
      <button
        type="button"
        onClick={() => {
          setHighlighted(countryCode);
          setCurrentSlide(idx);
        }}
        className={classNames(
          "flex flex-col max-w-[286px] w-full transition duration-200",
          {
            "scale-105 md:scale-110": currentSlide === idx,
          }
        )}
      >
        {image && (
          <Image {...image} aspectRatio={[286, 175]} preserveAspectRatio />
        )}
        <div className="bg-light-gray p-3 w-full">
          <Text variant="lg" className="font-semibold">
            {location}
          </Text>
        </div>
      </button>
    );
  }

  if (url?.length) {
    return (
      <AppLink
        to={url}
        className="mx-auto lg:mx-3 mb-6 rounded-md overflow-hidden shadow-2xl flex flex-col max-w-xs sm:max-w-[379px] w-full text-black bg-white items-stretch justify-start h-full"
      >
        {image && (
          <div>
            <Image {...image} aspectRatio={[286, 175]} preserveAspectRatio />
          </div>
        )}

        <div className="p-4 px-6 w-full text-center space-y-3">
          <Text variant="sm" className="text-blue uppercase font-semibold">
            {label}
          </Text>
          <Text variant="lg" className="font-semibold text-black px-5">
            {title}
          </Text>
          <Text variant="body">{description}</Text>
        </div>
      </AppLink>
    );
  }

  return (
    <div className="mx-auto lg:mx-3 mb-6 rounded-md overflow-hidden shadow-2xl flex flex-col max-w-xs sm:max-w-[379px] w-full text-black bg-white items-stretch justify-start h-full">
      {image && (
        <div>
          <Image {...image} aspectRatio={[286, 175]} preserveAspectRatio />
        </div>
      )}

      <div className="p-4 px-6 w-full text-center space-y-3">
        <Text variant="sm" className="text-blue uppercase font-semibold">
          {label}
        </Text>
        <Text variant="lg" className="font-semibold text-black px-5">
          {title}
        </Text>
        <Text variant="body">{description}</Text>
      </div>
    </div>
  );
};

CareerCard.defaultProps = {};

export default CareerCard;
