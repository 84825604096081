import React, { useRef } from "react";
import classNames from "classnames";
import { Container, Icon } from "@atoms";
import { t } from "@utils";
import { useAppState } from "@state/state";

const SearchControl = ({ queryState, resultsCount }) => {
  const [{ translation }] = useAppState();
  const { lang } = translation;
  const [query, setQuery] = queryState;
  const search = useRef();
  return (
    <div className="w-full p-6 pt-12 sm:py-12 md:pt-16 bg-light-gray">
      <Container>
        {!!resultsCount && (
          <div className="mb-3 text-dark-gray sm:mb-6">
            {resultsCount} {t("Results for:", lang)}
          </div>
        )}
        {!resultsCount && <div>Enter a search query:</div>}
        <div className="mt-3 sm:mt-6 relative z-10">
          <div className="flex items-center justify-center absolute left-0 top-0 bottom-0 z-10 p-3">
            <Icon
              name="search"
              className={classNames("w-5 h-5 text-dark-gray", {})}
            />
          </div>
          <input
            ref={search}
            value={query}
            onChange={() => setQuery(search.current.value)}
            onInput={() => setQuery(search.current.value)}
            className="bg-white border-solid border border-silver focus:border-blue w-full p-3 pl-10 text-lg relative z-0"
          />
        </div>
      </Container>
    </div>
  );
};

SearchControl.defaultProps = {};

SearchControl.propTypes = {};

export default SearchControl;
