import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query AppLinksQuery {
    craft {
      globalSet(handle: "appLinks") {
        ... on Craft_appLinks_GlobalSet {
          googlePlayUrl
          appStoreUrl
          extraAppStoreLinks {
            ... on Craft_extraAppStoreLinks_appStoreLink_BlockType {
              appStoreUrl {
                ariaLabel
                url
              }
              icon {
                ... on Craft_icon_icon_Entry {
                  iconOverride
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { googlePlayUrl, appStoreUrl, extraAppStoreLinks } = craft.globalSet;
  return {
    googlePlayUrl,
    appStoreUrl,
    extraAppStoreLinks: extraAppStoreLinks?.map(storeLink => {
      return {
        link: storeLink?.appStoreUrl,
        name: storeLink?.icon?.[0].title,
        icon: storeLink?.icon?.[0].iconOverride,
      };
    }),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
