import React, { useRef, useState, useEffect } from "react";
import { navigate } from "gatsby";
import classNames from "classnames";
import gsap from "gsap";
import { Icon } from "@atoms";
import { theme as tailwind } from "@tailwind";
import { t } from "@utils";
import { useAppState } from "@state/state";

const HeaderSearch = ({ location }) => {
  const [{ translation }] = useAppState();
  const { lang } = translation;
  const search = useRef();
  const query = useRef();
  const [searchVisible, setSearchVisible] = useState(false);

  const handleClickOutside = event => {
    if (
      searchVisible &&
      search.current &&
      !search.current.contains(event.target)
    ) {
      setSearchVisible(false);
      showSearch();
    }
  };

  const showSearch = () => {
    if (
      window.innerWidth > parseInt(tailwind.screens.md.replace("px", ""), 10)
    ) {
      if (!searchVisible) {
        gsap.to(search.current, {
          opacity: 1,
          onComplete: () => setSearchVisible(true),
          duration: 0.2,
        });
      } else {
        gsap.to(search.current, {
          opacity: 0,
          onComplete: () => setSearchVisible(false),
          duration: 0.2,
        });
      }
    } else {
      navigate(`${lang === "en" ? "" : `/${lang}`}/search`);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (query.current.value) {
      setSearchVisible(false);
      showSearch();
      navigate(
        `${lang === "en" ? "" : `/${lang}`}/search?q=${query.current.value}`
      );
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchVisible]);

  return (
    <>
      {/* query */}
      <form
        ref={search}
        onSubmit={handleSubmit}
        className={classNames(
          "absolute bg-white duration-200 inset-0 h-8 pl-3 flex -mt-1 opacity-0 z-[100]",
          {
            "pointer-events-none": !searchVisible,
          }
        )}
      >
        <input
          ref={query}
          placeholder={t("Search...", lang)}
          className="flex-grow placeholder-light-silver border-b border-silver focus:border-b-blue "
        />
        <button
          type="button"
          onClick={showSearch}
          className="ml-8 -mt-px overflow-visible"
        >
          <Icon
            name="search"
            className="w-6 md:w-3 text-black overflow-visible pl-[2px"
          />
        </button>
      </form>
      {/* To be thrown into a component to handle search */}
      <button type="button" onClick={showSearch} className="ml-8">
        <Icon
          name="search"
          className="w-6 md:w-3 text-black overflow-visible pl-[2px]"
        />
      </button>
    </>
  );
};

HeaderSearch.defaultProps = {};

HeaderSearch.propTypes = {};

export default HeaderSearch;
