import { useStaticQuery, graphql } from "gatsby";
import { resolveImage, resolveAboveCta } from "@dataResolvers";

const query = graphql`
  query Error404Query {
    craft {
      globalSets(handle: "error404") {
        language
        ... on Craft_error404_GlobalSet {
          heroTitle
          entrySubtitle
          heroCta {
            text
            url
          }
          metaFeaturedImage(withTransforms: ["heroTallExtraLarge"]) {
            width(transform: "heroTallExtraLarge")
            height(transform: "heroTallExtraLarge")
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url(transform: "heroTallExtraLarge")
          }
          aboveFooterCtaImageBlocks {
            ...AboveFooterFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const langs = {};
  craft.globalSets.forEach(global => {
    const {
      heroTitle,
      entrySubtitle,
      heroCta,
      metaFeaturedImage: heroImage,
      aboveFooterCtaImageBlocks,
    } = global;
    langs[global.language] = {
      hero: {
        title: heroTitle,
        subtitle: entrySubtitle,
        button: heroCta,
        image: resolveImage(heroImage),
      },
      cards: resolveAboveCta(aboveFooterCtaImageBlocks),
    };
  });
  return langs;
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
