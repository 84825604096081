import resolveImage from "./resolveImage";

const resolveCB = cb => {
  if (cb && cb.length) {
    const nestedCB = cb?.map(b => {
      return b?.contentBuilder;
    });
    const _cb = [...cb, ...nestedCB]?.flat();
    return _cb.map((_block, i) => {
      const block = _block;
      const { type, uid } = block || {};

      switch (type) {
        case "sideBySide":
          return {
            uid,
            type,
            image: resolveImage(block?.image),
            rightImage: block?.imagePosition === "right",
            heading: block?.heading,
            summary: block?.summary,
            link: block?.linkAnchor,
            linkType: block?.linkType,
            embedCode: block?.embedCode,
            blueVideoIcon: block?.blueVideoIcon,
            showAppLinks: block?.showAppLinks || block?.showAppStoreLinks,
          };
        case "sideBySideWithIcons":
          return {
            uid,
            type,
            image: resolveImage(block?.image),
            rightImage: block?.imagePosition === "right",
            heading: block?.heading,
            summary: block?.summary,
            link: block?.linkAnchor,
            linkType: block?.linkType,
            embedCode: block?.embedCode,
            blueVideoIcon: block?.blueVideoIcon,
            children: block?.children,
            showAppLinks: block?.showAppLinks || block?.showAppStoreLinks,
          };
        case "background":
          return {
            uid,
            type,
            image: resolveImage(block?.image),
            heading: block?.heading,
            summary: block?.summary,
            textPosition: block?.textPosition,
            link: block?.linkAnchor,
            linkType: block?.linkType,
          };
        case "fullwidthImageNoCopy":
          return {
            uid,
            type,
            image: resolveImage(block?.image),
          };
        case "backgroundWithIcons":
          return {
            uid,
            type,
            image: resolveImage(block?.image),
            heading: block?.heading,
            summary: block?.summary,
            textPosition: block?.textPosition,
            link: block?.linkAnchor,
            linkType: block?.linkType,
            children: block?.children,
          };
        case "threeColumnExcerpts":
          return {
            uid,
            type,
            heading: block?.heading,
            subheading: block?.subHeading,
            grayBackground: block?.lightGrayBackground,
            columns: block?.copyColumns?.map(column => {
              return {
                heading:
                  column?.item?.[0]?.aboveFooterCtaTitle ||
                  column?.item?.[0]?.title ||
                  column?.heading,
                subheading: column?.item?.[0]?.metaDescription || column?.copy,
                image: resolveImage(
                  column?.item?.[0]?.metaFeaturedImage || column?.image
                ),
                embedCode: column?.embedCode,
                link: column?.linkAnchor?.url
                  ? column?.linkAnchor
                  : { text: "Learn More", url: column?.item?.[0]?.website },
              };
            }),
          };
        case "twoColumnExcerpts":
          return {
            uid,
            type,
            heading: block?.heading,
            subheading: block?.subHeading,
            grayBackground: true,
            noHorizontalScroll: true,
            columns: block?.copyGrid?.map(item => {
              return {
                ...item,
                image: resolveImage(item?.image),
                width: "half",
                center: true,
              };
            }),
          };
        case "iconCopyColumns":
          return {
            uid,
            type,
            grayBackground: block?.lightGrayBackground,
            center: block?.columnWidth,
            noHorizontalScroll: true,
            lessWhitespace: cb[i - 1 || 0]?.type === "iconCopyColumns",
            columns: block?.children?.map(column => {
              return {
                icon: column?.icon || "name",
                iconOverride: column?.iconOverride,
                iconOverride2: column?.iconOverride2?.[0]?.svgCode,
                heading: column?.heading,
                subheading: column?.summary,
                link: column?.linkAnchor,
                hideImage: true,
                center: true,
                width: block?.columnWidth ? "half" : "third",
                extraSpacingBottom:
                  cb[i + 1 || i - 1]?.type !== "iconCopyColumns",
              };
            }),
          };
        case "iconGrid":
          return {
            uid,
            type,
            heading: block?.heading,
            subheading: block?.subheading || block?.subHeading,
            lightGrayBackground: block?.lightGrayBackground,
            icons: (block?.iconGrid || block?.children)?.map(icon => {
              return {
                icon: icon?.icon,
                iconOverride: icon?.iconOverride,
                iconOverride2:
                  icon?.icon?.[0]?.svgCode || icon?.iconOverride2?.[0]?.svgCode,
                heading: icon?.heading,
                subheading: icon?.subHeading,
                width: block?.columnWidth || "fourth",
                hideImage: true,
                center: block?.center,
                fullWidthMobile: true,
              };
            }),
          };
        case "quotes":
          return {
            uid,
            type,
            quotes: block?.quotes?.map(quote => {
              return {
                copy: quote?.copy,
                attribution: quote?.attribution,
                image: resolveImage(quote?.image),
              };
            }),
            imageTop: resolveImage(block?.image),
            imageBottom: resolveImage(block?.image2),
            carouselPosition: block?.quotePosition,
          };
        case "cta":
          return {
            uid,
            type,
            heading: block?.heading,
            summary: block?.summary,
            link: block?.buttonLink,
            link2: block?.secondaryButton,
            first: i === 0,
            last: i === cb.length - 1,
          };
        case "blockquote":
          return {
            uid,
            type,
            quote: block?.quote,
            attribution: block?.attribution,
          };
        case "copy":
          return {
            uid,
            type,
            copy: block?.copy,
          };
        case "image":
          return {
            uid,
            type,
            image: resolveImage(block?.image),
            caption: block?.caption,
          };
        case "videoEmbed":
          return {
            uid,
            type,
            code: block?.embedCode,
          };
        case "carousel":
          return {
            uid,
            type,
            carousel: block?.carousel?.map(slide => {
              if (slide?.entry?.length > 0) {
                return {
                  ...slide?.entry?.[0],
                  metaFeaturedImage: resolveImage(
                    slide?.entry[0]?.metaFeaturedImage
                  ),
                };
              }
              return {
                ...slide,
                metaFeaturedImage: resolveImage(slide?.image),
              };
            }),
          };
        case "locationsCarousel":
          return {
            uid,
            type,
            label: block?.label,
            heading: block?.heading,
            locations: block?.locations?.map(location => {
              return {
                image: resolveImage(location?.image),
                location: location?.location,
              };
            }),
          };
        case "impact":
          return {
            uid,
            type,
            icon: block?.icon?.[0]?.svgCode,
            heading: block?.heading,
            subheading: block?.subHeading,
            impacts: block?.impactCards?.map(impact => {
              return {
                label: impact?.label,
                title: impact?.heading,
                description: impact?.subHeading,
                url: impact?.impactUrl?.url,
              };
            }),
          };
        case "fullScreenVideo":
          return {
            uid,
            type,
            url: block?.videoUrl,
          };
        case "imageCopy":
          return {
            uid,
            type,
            label: block?.label,
            content: block?.copy,
            image: resolveImage(block?.image),
            button: block?.button,
            textPosition: block?.textPosition,
          };
        case "readMore":
          return {
            uid,
            type,
            color: block?.lightGrayBackground ? "gray" : "white",
            sections: block?.readMoreSections?.map(section => {
              return {
                label: section?.label,
                heading: section?.heading,
                content: section?.copy,
                images: section?.images,
              };
            }),
          };
        case "background2":
          return {
            uid,
            type,
            image: resolveImage(block?.image),
            heading: block?.heading,
            textPosition: block?.textPosition,
          };
        case "imageCarousel":
          return {
            uid,
            type,
            images: block?.images,
          };
        case "valueGrid":
          return {
            uid,
            type,
            heading: block?.heading,
            subheading: block?.subheading,
            values: block?.values,
          };
        case "jobPostings":
          return {
            uid,
            type,
            heading: block?.heading,
            subheading: block?.subheading,
            descriptionDefault: block?.descriptionDefault,
            footer: block?.footer,
            scrollText: block?.scrollText,
          };
        default:
          return null;
      }
    });
  }
  return null;
};

export default resolveCB;
