import React from "react";
import { useFooterData } from "@staticQueries";
import { Container, Text, Icon } from "@atoms";
import { AppLink } from "@base";

const Footer = ({ lang }) => {
  const data = useFooterData();
  const {
    nav,
    footerCtas,
    social,
    footerSublinks: sublinks,
    address,
    email,
  } = data[lang || "en"];
  return (
    <footer>
      <Container variant="2xl">
        <div className="mb-12 md:mb-16 border-2 border-light-gray border-l-0 border-r-0 flex flex-col sm:flex-row divide-light-gray divide-solid divide-y-2 sm:divide-y-0 sm:divide-x-2 justify-between">
          {footerCtas?.map((cta, i) => {
            // eslint-disable-next-line no-unsafe-optional-chaining
            const { text, url } = cta?.ctaItem;
            return (
              <AppLink
                to={url}
                key={`footerCta--${i + 1}`}
                className="group text-dark-gray hover:text-blue uppercase font-bold w-full sm:w-1/2 py-10 sm:py-12 flex justify-center"
              >
                <div className="flex flex-row items-center">
                  <Text variant="lg" className="tracking-wider">
                    {text}
                  </Text>
                  <Icon
                    name="arrowRight"
                    className="fill-blue ml-4 w-3 transform group-hover:scale-125"
                  />
                </div>
              </AppLink>
            );
          })}
        </div>

        <nav
          aria-label="Footer links"
          className="mt-2 flex flex-row flex-wrap lg:flex-nowrap justify-start items-start mb-12 md:mb-16"
        >
          {nav?.map((col, i) => {
            if (col?.type === "link") {
              return (
                <AppLink to={col?.link?.url}>
                  <Text>{col?.link?.text}</Text>
                </AppLink>
              );
            }
            if (col?.type === "links") {
              return (
                <div
                  key={`footerLink-${i + 1}`}
                  className="mb-3 w-1/2 sm:w-1/3 px-0 lg:px-4 first:pl-0 first:lg:pr-4 last:lg:pl-4 last:lg:pr-0"
                  aria-labelledby={`footerLink-${i}`}
                >
                  <div className="mb-4">
                    <Text variant="h5" id={`footerLink-${i}`}>
                      {col?.listHeader?.text}
                    </Text>
                  </div>
                  <ul>
                    {col?.list?.map((item, _i) => {
                      const { link } = item;
                      return (
                        <li
                          className="mb-3 mr-6"
                          key={`footerSublink--${i + 1}-${_i + 1}`}
                        >
                          <AppLink
                            to={link?.url}
                            className="w-auto inline-block"
                          >
                            <Text
                              variant="sm"
                              className="text-dark-gray hover:text-blue"
                            >
                              {link?.text}
                            </Text>
                          </AppLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            }
            return null;
          })}
        </nav>

        <div className="grid gap-y-6 grid-cols-2 md:grid-cols-[1fr,50%,1fr] mb-12 md:mb-16">
          <ul
            aria-label="Social links"
            className="flex flex-row space-x-4 w-full justify-center col-span-full md:col-auto md:justify-start"
          >
            {social?.map((s, i) => {
              // BA-DADA-DA-DAAAAAAA
              const { socialLink, icon } = s;
              return (
                <li key={`socialLink--${i + 1}`}>
                  <AppLink
                    to={socialLink?.url}
                    className="text-dark-gray hover:text-blue"
                    title={socialLink?.ariaLabel}
                    aria-label={socialLink?.ariaLabel}
                  >
                    <Icon name={icon} className="h-8 w-8" fitHeight />
                  </AppLink>
                </li>
              );
            })}
          </ul>

          <div className="flex flex-col justify-center items-center col-span-full md:col-auto">
            <ul className="flex flex-wrap text-center justify-center leading-8">
              {address && (
                <li className="flex items-center text-dark-gray">
                  <AppLink
                    to={`http://maps.google.com/maps?q=${encodeURIComponent(
                      address
                    )}`}
                    className="text-dark-gray hover:text-blue"
                  >
                    <Text className="text-xs sm:text-sm">{address}</Text>
                  </AppLink>
                </li>
              )}
              {email && (
                <li className="flex items-center text-dark-gray">
                  <div className="border-r-1.5 h-3 border-silver mx-1" />
                  <AppLink
                    to={email?.url}
                    className="text-dark-gray hover:text-blue"
                  >
                    <Text className="text-xs sm:text-sm">{email?.text}</Text>
                  </AppLink>
                </li>
              )}
            </ul>
            <ul className="flex flex-wrap text-center justify-center leading-8">
              {sublinks?.map((link, i) => {
                const { linkIt } = link;
                return (
                  <li
                    className="flex items-center text-dark-gray"
                    key={`subLink--${i + 1}`}
                  >
                    {/* eslint-disable-next-line eqeqeq */}
                    {i != 0 && (
                      <div className="border-r-1.5 h-3 border-silver mx-1 align-middle self-center" />
                    )}
                    <AppLink
                      to={linkIt?.url}
                      className="w-auto inline-flex text-dark-gray hover:text-blue"
                      title={linkIt?.text}
                    >
                      <Text className="text-xs sm:text-sm">{linkIt?.text}</Text>
                    </AppLink>
                  </li>
                );
              })}
            </ul>
            {lang === "zh" && (
              <div className="flex items-center text-sm mt-2 font-bold justify-center">
                <a
                  href="http://beian.miit.gov.cn"
                  target="_blank"
                  rel="noreferrer"
                >
                  沪ICP备2021006871号-2
                </a>
              </div>
            )}
          </div>
        </div>
      </Container>
    </footer>
  );
};

Footer.defaultProps = {};

Footer.propTypes = {};

export default Footer;
