import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query IconsQuery {
    craft {
      globalSet(handle: "icons") {
        ... on Craft_icons_GlobalSet {
          icons {
            ... on Craft_icons_icon_BlockType {
              iconName
              svgCode
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { icons } = craft?.globalSet;

  return {
    icons,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
