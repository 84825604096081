import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";

const Text = ({
  children,
  className: _className,
  variant,
  richText,
  quotes,
  id,
}) => {
  let Tag = "span";
  let classes = "text-base";
  switch (variant) {
    case "h1":
      Tag = "h1";
      classes =
        "font-heading font-semibold text-3xl sm:text-5.5xl leading-none block";
      break;
    case "h2":
      Tag = "h2";
      classes =
        "font-heading font-semibold text-2.5xl sm:text-3.5xl leading-none block";
      break;
    case "h3":
      Tag = "h3";
      classes = "font-heading font-semibold text-2xl leading-none block";
      break;
    case "h4":
      Tag = "h4";
      classes = "font-heading font-semibold text-1.5xl leading-none block";
      break;
    case "h5":
      Tag = "h5";
      classes = "font-heading font-semibold text-1xl leading-none block";
      break;
    case "2xl":
      classes = "block text-xl lg:text-2xl leading-normal font-sans";
      break;
    case "xl":
      classes =
        "block text-base md:text-lg lg:text-xl leading-normal font-sans";
      break;
    case "lg":
      classes = "block text-lg leading-normal font-sans";
      break;
    case "body":
      classes = "block text-base leading-normal font-sans";
      break;
    case "sm":
      classes = "block text-sm leading-normal font-sans";
      break;
    case "xs":
      classes = "block text-xs font-sans leading-tight";
      break;
    case "2xs":
      classes = "block text-xxs font-sans leading-tight";
      break;
    case "label":
      classes =
        "block text-xs font-bold leading-none font-sans uppercase tracking-0.5";
      break;
    default:
      classes = "";
      break;
  }
  return React.Children.toArray(children).map((child, i) => {
    // const components = [RichText, Phone];
    // if children are not components (html or string), then render the html
    if (typeof child.type === "undefined") {
      return (
        <Tag
          key={`text-${Tag}--${i + 1}`}
          className={classNames(
            "",
            classes,
            _className,
            { "rich-text": richText },
            { quotes }
          )}
          dangerouslySetInnerHTML={{ __html: child }}
          id={id}
        />
      );
    }
    // otherwise return children
    return (
      <Tag
        key={`text-${Tag}--${i + 1}`}
        className={classNames(
          "",
          classes,
          _className,
          { "rich-text": richText },
          { quotes }
        )}
        id={id}
      >
        {child}
      </Tag>
    );
  });
};

Text.defaultProps = {
  tag: "span",
  className: null,
  variant: null,
  richText: true,
  quotes: false,
  children: null,
};

Text.propTypes = {
  className: propTypes.string,
  tag: propTypes.string,
  variant: propTypes.string,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]),
  richText: propTypes.bool,
};

export default Text;
