import en from "./en";
import es from "./es";
import pt from "./pt";
import zh from "./zh";
import ru from "./ru";
import de from "./de";

export default {
  en,
  es,
  pt,
  zh,
  ru,
  de,
};
