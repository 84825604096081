import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { Image, Button, Text, Icon } from "@atoms";
import { AppLink } from "@base";
import gsap from "gsap";
import { useHeaderData } from "@staticQueries";

import { useAppState } from "@state/state";

const SubNav = ({ header, subnav }) => {
  const [{ translation }] = useAppState();
  const { lang } = translation;
  const data = useHeaderData();
  const { newProductText } = data[lang || "en"];
  const subnavContainer = useRef();
  const triangle = useRef();
  const tl = useRef();

  // Handling the hover state of the subnavs
  const [active, setActive] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (subnavContainer.current) {
      tl.current = gsap
        .timeline({ paused: true })
        .to(subnavContainer.current, {
          y: 0,
          pointerEvents: "auto",
          opacity: 1,
          duration: 0.05,
          ease: "power2.inOut",
          onComplete: () => {
            setExpanded(true);
          },
          onReverseComplete: () => {
            setExpanded(false);
          },
        })
        .to(
          triangle.current,
          {
            y: 0,
            pointerEvents: "auto",
            opacity: 100,
            duration: 0.05,
            ease: "power2.inOut",
          },
          "<"
        );
    }
  }, []);

  useEffect(() => {
    if (active && !expanded) {
      tl.current.play();
    } else {
      tl.current.reverse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <div
      className="group h-full"
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <AppLink
        to={header?.url !== "/#" ? header?.url : null}
        onClick={() => setActive(false)}
        className="block h-full transition hover:text-blue relative px-6 xl:px-8 py-7"
      >
        <div
          ref={triangle}
          className="mt-2 header-triangle transition opacity-0 transform translate-y-8"
        />
        <Text variant="sm" className="uppercase font-bold tracking-wider">
          {header?.text}
        </Text>
      </AppLink>
      <div
        className="transform translate-y-8 pointer-events-none transition opacity-0 absolute left-0 right-0 w-full"
        ref={subnavContainer}
        aria-expanded={expanded}
      >
        <ul className="bg-light-gray py-8 flex justify-center flex-wrap">
          {subnav?.map((sub, i) => {
            return (
              <li
                key={`subnav--${i + 1}`}
                className={classNames("block w-40 relative mx-6 lg:mx-10", {
                  "flex flex-col justify-center items-center": sub?.useIcons,
                })}
              >
                {sub?.newProducts && (
                  <div className="flex items-center flex-nowrap absolute -top-4">
                    <Icon name="plusCircle" className="text-blue mr-1.5 w-5" />
                    <Text variant="2xs" className="uppercase font-bold">
                      {newProductText || "New Products!"}
                    </Text>
                  </div>
                )}
                {sub?.image && (
                  <div className="w-32 mb-3">
                    <AppLink
                      to={sub?.learnMore?.url}
                      className="block w-full h-full"
                      onClick={() => setActive(false)}
                    >
                      <Image {...sub?.image} placeholder={false} />
                    </AppLink>
                  </div>
                )}
                {sub?.useIcons && (
                  <AppLink
                    to={sub?.learnMore?.url}
                    className="text-black hover:text-blue"
                    onClick={() => setActive(false)}
                  >
                    <div className="mb-3 flex justify-center items-center w-20 h-20 rounded-full overflow-hidden bg-white">
                      <Icon
                        override={sub?.icon}
                        className="w-12 h-10"
                        fitHeight
                      />
                    </div>
                  </AppLink>
                )}
                <div className={classNames("", { "ml-4": !sub?.useIcons })}>
                  <Text variant="sm" className="mb-1 font-semibold">
                    {sub?.heading}
                  </Text>
                  {!sub?.useIcons && sub?.learnMore?.url && (
                    <Button
                      to={sub?.learnMore?.url}
                      arrow
                      small
                      onClick={() => setActive(false)}
                    >
                      <Text
                        variant="2xs"
                        className="normal-case font-normal tracking-normal"
                      >
                        {sub?.learnMore?.text}
                      </Text>
                    </Button>
                  )}
                  <ul className="mt-3">
                    {sub?.extraLinks?.map((extra, _i) => {
                      return (
                        <li className="mt-1" key={`header-extra--${_i + 1}`}>
                          <AppLink
                            to={extra?.url}
                            onClick={() => setActive(false)}
                          >
                            <Text variant="2xs" className="text-blue underline">
                              {extra?.text}
                            </Text>
                          </AppLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

SubNav.defaultProps = {};

SubNav.propTypes = {};

export default SubNav;
