const gtmID = process.env.GATSBY_GTM_ID;

const loadGTM = consent => {
  // load gtm and register a routeChange
  // eslint-disable-next-line func-names
  (function (w, d, s, l, i) {
    // eslint-disable-next-line no-param-reassign
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    // consent does not work
    // if (consent) {
    //   // store ad data
    //   w[l].push("consent", "default", {
    //     ad_storage: "granted",
    //     analytics_storage: "granted",
    //   });
    // } else {
    //   // don't store ad data
    //   w[l].push("consent", "default", {
    //     ad_storage: "denied",
    //     analytics_storage: "denied",
    //   });
    // }
    // if consetn is given, tell gtm gdpr is accepted
    if (consent) {
      w[l].push({
        event: "gdpr-accepted", // used to trigger other events in GTM, namely loading Hubspot and Google Analytics
        gdprAccepted: true, // used by other triggers to determine if they're allowed to run
        doNotTrack: window.localStorage.getItem("doNotTrack"), // used to filter out internal Cosmic traffic
      });
    }
    w[l].push({ event: "routeChange" });

    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== "dataLayer" ? `&l=${l}` : "";
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", gtmID);
  // send initial page view
  // set permissions based on consent
};

export default loadGTM;
