import { useStaticQuery, graphql } from "gatsby";
import resolveImage from "../dataResolvers/resolveImage";

const query = graphql`
  query DefaultsQuery {
    craft {
      globalSets(handle: "defaults", site: "*") {
        language
        ... on Craft_defaults_GlobalSet {
          metaPageTitle
          metaFeaturedImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const langs = {};
  craft.globalSets.forEach(global => {
    const { metaPageTitle, metaFeaturedImage } = global;
    langs[global.language] = {
      meta: {
        title: metaPageTitle,
        image: resolveImage(metaFeaturedImage),
      },
    };
  });
  return langs;
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
