import React from "react";
import propTypes from "prop-types";
import { CbWrapper, Container } from "@atoms";
import classNames from "classnames";

const EmbedCode = ({ code: _code, type, blog }) => {
  // standardize urls + strip duplicitous code
  const code = _code
    ?.replace("https://", "//")
    ?.replace("http://", "//")
    ?.replace("//", "https://");
  if (typeof window !== "undefined") {
    return (
      <Container variant="lg" interior={blog} padding={blog}>
        <CbWrapper type={type}>
          <div className={classNames("max-w-3xl", { "mx-auto": !blog })}>
            <div className="embedCode videoEmbed">
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: code }} />
            </div>
          </div>
        </CbWrapper>
      </Container>
    );
  }
  return <div />;
};

EmbedCode.propTypes = {
  code: propTypes.string.isRequired,
};

export default EmbedCode;
