import resolveImage from "./resolveImage";

const resolveAboveCta = cards => {
  if (cards?.length > 0) {
    return cards?.map(block => {
      const { blockEntry } = block || {};
      return {
        title:
          blockEntry?.[0]?.aboveFooterCtaTitle ||
          block?.blockTitle ||
          block?.title,
        subtitle:
          blockEntry?.[0]?.aboveFooterCtaSubtitle ||
          block?.blockSubtitle ||
          block?.subtitle,
        image: resolveImage(
          blockEntry?.[0]?.metaFeaturedImage ||
            block?.blockImage ||
            block?.metaFeaturedImage
        ),
        url:
          blockEntry?.[0]?.website ||
          blockEntry?.[0]?.url ||
          block?.url ||
          block?.blockUrl,
      };
    });
  }
  return null;
};

export default resolveAboveCta;
