import React from "react";
import { Image, Text } from "@atoms";
import { AppLink } from "@base";

const SearchResult = ({ title, description, image, url }) => {
  return (
    <AppLink className="flex flex-wrap -mx-3" to={url}>
      <div className="w-full sm:w-1/4 min-h-1/7-screen relative px-3">
        <Image url={image} width="100" height="100" fill />
      </div>
      <div className="w-full sm:w-3/4 mt-3 sm:mt-0 p-3">
        <Text variant="h4" className="text-blue">
          {title}
        </Text>
        <Text variant="lg" className="text-dark-gray mt-3">
          {description}
        </Text>
      </div>
    </AppLink>
  );
};

SearchResult.defaultProps = {};

SearchResult.propTypes = {};

export default SearchResult;
