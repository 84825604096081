import React, { useState } from "react";
import classNames from "classnames";
import { Text, Button, Image, Icon, Table } from "@atoms";
import { AppLink } from "@base";
import { useAppState } from "@state/state";
import { t } from "@utils";

const Column = ({
  heading,
  subheading,
  summary,
  image,
  icon,
  iconOverride,
  iconOverride2,
  embedCode,
  link,
  url,
  width,
  fullWidthMobile,
  centerTitle,
  fullWidthImage,
  hideImage,
  blueVideoIcon,
  center,
  specs,
  eventStartDate,
  eventEndDate,
  spacingBottom,
  extraSpacingBottom,
  transmitters,
  index: _i,
  model,
  category,
  postDate,
  author,
  paddingSides,
  swapAuthorAndDate,
  imageUrl,
  learnMoreText,
  className: _className,
  imageClass,
  imageFill,
}) => {
  const [{ translation }, dispatch] = useAppState();
  const { lang } = translation;

  const hasUrl = imageUrl || link?.url;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [openTable, setOpenTable] = model || useState();

  const openModal = e => {
    dispatch({
      type: "openModal",
      content: embedCode ? (
        <div className="max-w-3xl lg:max-w-5xl w-full">
          <Text richText={false} className="block videoEmbed">
            {embedCode}
          </Text>
        </div>
      ) : null,
    });
  };

  return (
    <div
      className={classNames(
        "flex-shrink-0 md:flex-shrink mb-8 lg:mb-0",
        { "w-full": fullWidthMobile },
        { "w-[80%]": !fullWidthMobile },
        { "w-full": width === "full" },
        { "w-full md:w-1/2 pb-0 lg:pb-8": width === "half" },
        { "xs:w-1/2 md:w-1/4": width === "fourth" },
        { "xs:w-1/2 md:w-1/3": width === "third" },
        { "text-center": center },
        { "lg:mb-8": spacingBottom },
        { "!mb-16 lg:mb-16": extraSpacingBottom },
        { "sm:px-4 md:px-8": paddingSides },
        { relative: url },
        _className
      )}
    >
      <div className="">
        {hideImage && icon && (
          <div className="mb-4 md:mb-8">
            <Icon
              name={iconOverride || icon}
              override={iconOverride2}
              className={classNames(
                "text-blue",
                { "w-20 h-20": width !== "fourth" },
                { "w-10 h-10 md:w-16 md:h-16": width === "fourth" }
              )}
              fitHeight
            />
          </div>
        )}
        {!hideImage && (
          <div className="w-full mb-6 relative">
            {image && !hasUrl && (
              <div
                className={classNames("relative z-0 mx-auto", {
                  "max-w-[400px]": !fullWidthImage,
                })}
              >
                {!imageFill && <Image {...image} fitHeight />}
                {imageFill && (
                  <div className={classNames(imageClass, "relative")}>
                    <Image {...image} fitHeight fill={imageFill} />
                  </div>
                )}
              </div>
            )}
            {image && hasUrl && (
              <AppLink to={imageUrl || link?.url}>
                <div
                  className={classNames("relative z-0 mx-auto", {
                    "max-w-[400px]": !fullWidthImage,
                  })}
                >
                  {!imageFill && (
                    <Image
                      {...image}
                      className={imageClass}
                      fitHeight
                      fill={imageFill}
                    />
                  )}

                  {imageFill && (
                    <div className={classNames(imageClass, "relative")}>
                      <Image {...image} fitHeight fill={imageFill} />
                    </div>
                  )}
                </div>
              </AppLink>
            )}
            {embedCode && (
              <button
                type="button"
                className="absolute inset-0 z-20 group w-full h-full flex justify-center items-center"
                onClick={openModal}
                arial-label={t("Play video", lang)}
              >
                <Icon
                  name="play"
                  className={classNames(
                    "w-16 transform duration-200 transition group-hover:scale-110",
                    { "text-blue fill-blue": blueVideoIcon },
                    { "text-white fill-white": !blueVideoIcon }
                  )}
                />
              </button>
            )}
          </div>
        )}
        {category && (
          <Text
            variant="xs"
            className="my-6 font-bold text-blue uppercase tracking-wider"
          >
            {category}
          </Text>
        )}
        <Text
          variant={hideImage && icon ? "h4" : "h3"}
          className={classNames("text-black leading-tight", {
            "text-center": centerTitle,
          })}
        >
          {hasUrl ? (
            <AppLink to={hasUrl} className="!no-underline !text-inherit">
              {heading}
            </AppLink>
          ) : (
            heading
          )}
        </Text>
        {eventStartDate && (
          <Text
            variant="sm"
            className="my-6 text-blue"
          >{`${eventStartDate} - ${eventEndDate}`}</Text>
        )}
        {postDate && author && !swapAuthorAndDate && (
          <Text
            variant="sm"
            className="my-6"
          >{`${postDate} by ${author}`}</Text>
        )}
        {postDate && author && swapAuthorAndDate && (
          <Text
            variant="sm"
            className="my-6 text-dark-gray"
          >{`${author} &mdash; ${postDate}`}</Text>
        )}
        <Text variant="body" className="my-6 text-dark-gray">
          {summary || subheading}
        </Text>
        {specs?.length > 0 && (
          <ul className="my-6">
            {specs?.map((spec, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <li className="flex justify-between mt-4" key={i}>
                  <Text variant="body">{spec?.heading}</Text>
                  <Text variant="body" className="text-blue">
                    {spec?.value}
                  </Text>
                </li>
              );
            })}
          </ul>
        )}
      </div>

      <div className={classNames("", { "flex justify-center": center })}>
        {link?.url && (
          <Button
            arrow
            to={embedCode ? null : link?.url}
            aria-label={
              embedCode ? t("Play video", lang) : link?.ariaLabel || link?.text
            }
            onClick={() => {
              if (embedCode) {
                openModal();
              }
            }}
          >
            {t(link?.text, lang)}
          </Button>
        )}
      </div>
      {transmitters && (
        <div>
          <Button
            arrow
            aria-label={`${t("View the transmitters for", lang)} ${heading}`}
            onClick={e => {
              setOpenTable(openTable !== _i ? _i : null);
            }}
            transmitterActive={openTable === _i}
          >
            {learnMoreText}
          </Button>
          <Table
            headers={[
              t("Name", lang),
              t("ID", lang),
              t("Color", lang),
              t("Price", lang),
            ]}
            content={transmitters}
            open={openTable === _i}
            index={_i}
          />
        </div>
      )}
      {url && <AppLink to={url} className="w-full h-full absolute inset-0" />}
    </div>
  );
};

Column.defaultProps = {
  width: "third",
  paddingSides: true,
  swapAuthorAndDate: false,
};

Column.propTypes = {};

export default Column;
