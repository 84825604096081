export default {
  Name: "Nome",
  ID: "ID",
  Color: "Cor",
  Price: "Preço",
  "Learn More": "Saber mais",
  "Play video": "Reproduzir vídeo",
  "View the transmitters for": "Ver os transmissores para",
  "Select one": "Selecione um",
  "All Resources": "Todos os recursos",
  Showing: "Mostrando",
  "Job Title": "Cargo",
  Company: "Companhia",
  Email: "E-mail",
  "You have already been added to the newsletter list.":
    "Você já foi adicionado à lista de newsletter.",
  "Please fill out all required fields":
    "Por favor preencha todos os campos requeridos",
  Submit: "Enviar",
  "PDF Downloads": "Downloads de PDF",
  Videos: "Vídeos",
  "Related Articles": "Artigos relacionados",
  Share: "Participação",
  "Load More Articles": "Carregar mais artigos",
  "Read Article": "Leia o artigo",
  "Next Article": "Próximo artigo",
  "Next Event": "Próximo evento",
  Explore: "Explorar",
  "Read More": "Consulte Mais informação",
  Includes: "Inclui",
  "Search articles": "Pesquisar artigos",
  "Play Video": "Reproduzir vídeo",
};
