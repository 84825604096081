/* eslint-disable prefer-destructuring */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Suspense, useRef, useEffect } from "react";
import classNames from "classnames";
import "../../pcss/video.pcss";
import Image from "./Image";

const Video = ({ url: _url, muted, autoplay, placeholder, play, loop }) => {
  let url;
  const plyr = useRef();
  const player = useRef();
  const options = {
    muted,
    loop: { active: loop },
  };
  const provider = _url.match("http(s)?://(www.)?youtube|youtu.be")
    ? "youtube"
    : "vimeo";
  if (provider === "vimeo " && !_url.match(/vimeo\.com\/(\d+)/)) {
    return null;
  }

  if (provider === "youtube") {
    if (_url.match("embed")) {
      url = _url.split(/embed\//)[1].split('"')[0];
    } else {
      url = _url.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/)[0];
    }
  }

  if (provider === "vimeo") {
    url = _url.split("/").pop();
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (player.current) {
      if (play) {
        player.current.play();
      } else {
        player.current.pause();
      }
    }
  }, [play]);

  if (typeof window !== "undefined" && url) {
    // eslint-disable-next-line global-require
    const Plyr = require("plyr");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!player.current) {
        if (plyr.current && Plyr) {
          player.current = new Plyr(plyr.current);
          player.current.on("ready", event => {
            if (!play) {
              player.current.pause();
            }
          });
        }
      }
    }, []);
    // set up the player
    return (
      <Suspense
        fallback={
          <div>
            <Image {...placeholder} />
          </div>
        }
      >
        <div data-plyr-config={JSON.stringify(options)} ref={plyr}>
          <iframe
            src={
              provider === "youtube"
                ? `https://www.youtube.com/embed/${url}`
                : `https://player.vimeo.com/video/${url}`
            }
            allowFullScreen
            allowTransparency
            className={classNames({ hidden: player.current })}
          />
        </div>
      </Suspense>
    );
  }
  return (
    <div>
      <Image {...placeholder} />
    </div>
  );
};

Video.defaultProps = {
  play: true,
};

export default Video;
