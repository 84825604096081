import React from "react";
import classNames from "classnames";
import { Text, Icon, Image, Button } from "@atoms";
import { AppLink } from "@base";
import { useAppState } from "@state/state";
import { IconListItem } from "@molecules";
import { useApplinksData } from "@staticQueries";
import { t } from "@utils";

const CbSideBySide = ({
  image,
  rightImage,
  heading,
  summary,
  showAppLinks,
  link,
  linkType,
  embedCode,
  blueVideoIcon,
  children,
}) => {
  const [{ translation }, dispatch] = useAppState();
  const { lang } = translation;

  const { googlePlayUrl, appStoreUrl, extraAppStoreLinks } = useApplinksData();

  const openModal = e => {
    dispatch({
      type: "openModal",
      content: embedCode ? (
        <div className="max-w-3xl lg:max-w-5xl w-full">
          <Text richText={false} className="block videoEmbed">
            {embedCode}
          </Text>
        </div>
      ) : null,
    });
  };

  return (
    <section
      className={classNames(
        "min-h-none md:min-h-[38rem] flex flex-col md:flex-row",
        { "md:flex-row-reverse": rightImage }
      )}
    >
      <div
        className={classNames(
          "flex bg-dark-blue w-full md:w-1/2 mt-2 relative",
          { "ml-2": rightImage },
          { "mr-2": !rightImage }
        )}
      >
        <div
          className={classNames("w-full h-full overflow-hidden", {
            group: embedCode,
          })}
        >
          <div className="w-full h-[62.5vw] md:h-full transform transition duration-200 group-hover:scale-105 relative">
            <Image {...image} fill />
          </div>
          {embedCode && (
            <button
              type="button"
              className=" w-full h-full flex justify-center items-center absolute inset-0 z-20"
              onClick={openModal}
              arial-label={t("Play video", lang)}
            >
              <Icon
                name="play"
                className={classNames(
                  "w-12",
                  { "text-blue": blueVideoIcon },
                  { "text-white": !blueVideoIcon }
                )}
              />
            </button>
          )}
        </div>
      </div>

      <div className="w-full md:w-1/2 flex flex-col justify-center p-8 lg:p-16 text-dark-gray mt-2">
        <Text variant="h2" className="text-dark-gray">
          {heading}
        </Text>
        <Text
          variant="body"
          className={classNames("mt-8", {
            "mb-8": (link?.url && link?.text) || showAppLinks,
          })}
        >
          {summary}
        </Text>
        {link?.url && (
          <>
            {(linkType === "video" || (linkType === "text" && embedCode)) && (
              <Button
                arrow
                aria-label={t("Play video", lang)}
                onClick={() => {
                  if (embedCode) {
                    openModal();
                  }
                }}
              >
                {link?.text}
              </Button>
            )}
            {linkType === "button" && embedCode && (
              <div className={classNames("", { "mb-8": children })}>
                <Button
                  aria-label={t("Play video", lang)}
                  className="self-start group text-dark-gray hover:text-blue uppercase font-bold"
                  onClick={() => {
                    if (embedCode) {
                      openModal();
                    }
                  }}
                >
                  <Text variant="lg" className="tracking-wider">
                    {link?.text}
                  </Text>
                </Button>
              </div>
            )}
            {linkType === "text" && !embedCode && (
              <Button
                to={link?.url}
                arrow
                className="self-start group text-dark-gray hover:text-blue uppercase font-bold"
              >
                <div className="flex flex-row items-center">
                  <Text variant="lg" className="tracking-wider">
                    {link?.text}
                  </Text>
                </div>
              </Button>
            )}
            {linkType === "button" && !embedCode && (
              <div>
                <Button to={link?.url} className="self-start group">
                  <div className="flex flex-row items-center">
                    <Text variant="lg" className="tracking-wider">
                      {link?.text}
                    </Text>
                  </div>
                </Button>
              </div>
            )}
          </>
        )}
        {showAppLinks && (
          <div className="flex flex-row space-x-4">
            <AppLink to={googlePlayUrl}>
              <Icon name="appGooglePlay" className="h-10" fitHeight />
            </AppLink>

            <AppLink to={appStoreUrl}>
              <Icon name="appAppStore" className="h-10" fitHeight />
            </AppLink>

            {extraAppStoreLinks?.map((store, i) => {
              return (
                <AppLink
                  to={store?.link?.url}
                  ariaLabel={store?.link?.ariaLabel}
                >
                  <Icon
                    override={store?.icon}
                    className="h-10 fill-red"
                    fitHeight
                    noSanitize
                  />
                </AppLink>
              );
            })}
          </div>
        )}
        {children && (
          <ul className="mt-4">
            {children?.map((icon, i) => {
              // eslint-disable-next-line react/no-array-index-key
              return <IconListItem {...icon} blue key={i} />;
            })}
          </ul>
        )}
      </div>
    </section>
  );
};

CbSideBySide.defaultProps = {};

CbSideBySide.propTypes = {};

export default CbSideBySide;
