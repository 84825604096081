const resolveAddress = address => {
  const { name, street, street2, postalCode, city, state, country } =
    address || {};
  return `${name?.length ? `${name}<br>` : ""}
  ${street?.length ? `${street}<br>` : ""}
  ${street2?.length ? `${street2}<br>` : ""}
  ${city?.length ? `${city}, ` : ""}${state?.length ? `${state}, ` : ""}${
    postalCode?.length ? `${postalCode}, ` : ""
  } ${country}`;
};

export default resolveAddress;
