import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { AppLink } from "@base";
import { Text, Icon, Image } from "@atoms";
import gsap from "gsap";
import { useAppState } from "@state/state";

const MobileHeaderDropdown = ({ header, subnav }) => {
  const dropdownContainer = useRef();
  const [isActive, setIsActive] = useState(false);

  // Mobile nav triggers
  const [{ layout }, dispatch] = useAppState();
  const { showNav } = layout;

  useEffect(() => {
    if (isActive) {
      gsap.to(dropdownContainer.current, { height: "auto", duration: 0.3 });
    } else {
      gsap.to(dropdownContainer.current, { height: 0, duration: 0.3 });
    }
  }, [isActive]);

  useEffect(() => {
    if (!showNav) {
      setIsActive(false);
    }
  }, [showNav]);

  return (
    <div>
      <div className="w-full bg-white">
        <button
          type="button"
          className="flex justify-center items-center w-full p-2 text-center group"
          onClick={() => {
            if (isActive) {
              setIsActive(false);
            } else {
              setIsActive(true);
            }
          }}
        >
          <Text
            variant="body"
            className="text-black group-hover:text-blue uppercase font-bold tracking-widest"
          >
            {header?.text}
          </Text>
          <Icon
            name="dropdown"
            className={classNames(
              "w-10 group-hover:text-blue group-hover:fill-blue transition duration-300 ease-in-out",
              { "rotate-180": isActive }
            )}
          />
        </button>
        {/* Subnav */}
        <div
          className="flex flex-col bg-light-gray h-0 overflow-hidden"
          ref={dropdownContainer}
        >
          {subnav?.map((sub, i) => {
            return (
              <AppLink
                key={`sub--${i + 1}`}
                to={sub?.learnMore?.url}
                className="flex justify-center items-center w-full p-2"
                onClick={() => {
                  dispatch({
                    type: "hideNav",
                  });
                }}
              >
                {sub?.image && (
                  <div className="w-16 mr-3">
                    <Image {...sub?.image} placeholder={false} />
                  </div>
                )}
                {sub?.useIcons && (
                  <div className="mr-3 flex justify-center items-center w-10 h-10 rounded-full overflow-hidden bg-white">
                    <Icon
                      name="name"
                      override={sub?.icon}
                      className="w-6 h-5 text-black"
                      fitHeight
                    />
                  </div>
                )}
                <Text variant="sm" className="mb-1 font-semibold">
                  {sub?.heading}
                </Text>
              </AppLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

MobileHeaderDropdown.defaultProps = {};

MobileHeaderDropdown.propTypes = {};

export default MobileHeaderDropdown;
