/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Text, Icon, Button } from "@atoms";
import { AppLink } from "@base";
import { useAppState } from "@state/state";
import { Share } from "@molecules";
import { toggleBodyScroll } from "@utils";
import gsap from "gsap";

const SummaryDetails = () => {
  const summaryRef = useRef();

  const tl = useRef();
  const shareDialogue = useRef();
  const [showShareDialogue, setShowShareDialogue] = useState();
  const [isPrinting, setIsPrinting] = useState(false);

  const [{ summary: _summary }, dispatch] = useAppState();
  const { open, content } = _summary;
  const {
    heading,
    receiverHeading,
    transmitterHeading,
    displayHeading,
    accessoriesHeading,
    price,
    priceHeading,
    priceSubheading,
    priceSecondary,
    link2,
    link3,
    link4,
    linkAnchor,
    contactUsText,
    productDescription,
    transmittersDescription,
    displaysDescription,
    includes,
    productImage,
    shareUrl,
    showFinanceText,
    disclosureButtonText,
    disclosureHeading,
    disclosureCopy,
    tradeIn,
  } = content;

  useEffect(() => {
    if (summaryRef.current) {
      tl.current = gsap
        .timeline({
          paused: true,
          onComplete: () => {
            toggleBodyScroll(false);
          },
          onReverseComplete: () => {
            toggleBodyScroll(true);
          },
        })
        // .set(summaryRef.current, { x: "100%", pointerEvents: "none" })
        .to(summaryRef.current, {
          x: 0,
          duration: 0.3,
          ease: "power2.inOut",
          pointerEvents: "auto",
        });
    }
  }, []);

  useEffect(() => {
    if (open) {
      tl.current.play();
    } else {
      tl.current.reverse();
    }
  }, [open]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("popstate", () => {
        dispatch({
          type: "closeSummary",
        });
      });
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("beforeprint", () => {
        setIsPrinting(true);
      });

      window.addEventListener("afterprint", () => {
        setIsPrinting(false);
      });
    }
  }, []);

  const printSummary = async () => {
    // Safari is doesn't like being told to print with js so things break.
    // Once it's cancelled once, it goes faster than the page can get pulled in to
    // print the appropriate stuff. Apparently there's not a consistent fix for
    // Safari 15+ and sadly setTimeouts don't solve it

    // https://gist.github.com/capturetheflag/a588f4d5919efebf2eae315aeb9e2297 and
    // https://stackoverflow.com/questions/50455545/safari-javascript-wait-for-print-confirmation-window
    if (typeof window !== "undefined") {
      const isSafari = navigator.userAgent.indexOf("Safari") !== -1;
      const matchPrinting = window.matchMedia("print");

      if (!window.onafterprint && matchPrinting && !isPrinting) {
        if (isSafari) {
          try {
            document.execCommand("print", false, null);
          } catch (e) {
            window.print();
          }
        } else {
          window.print();
        }
      }
    }
  };

  const openModal = () => {
    dispatch({
      type: "openModal",
      content: (
        <div className="max-w-3xl lg:max-w-5xl w-full bg-white">
          <div className="p-4 border-b border-light-gray">
            <Text variant="h3">{disclosureHeading}</Text>
          </div>
          <Text className="block p-4 h-full max-h-[40rem] overflow-y-auto">
            {disclosureCopy}
          </Text>
        </div>
      ),
    });
  };

  return (
    <section
      ref={summaryRef}
      className="translate-x-full fixed print:text-black print:relative print:min-h-screen print:translate-x-0 w-full h-full inset-0 z-50 pointer-events-none"
    >
      <div
        className="w-full h-full cursor-[zoom-out] absolute print:relative inset-0 z-10"
        onClick={() => {
          dispatch({ type: "closeSummary" });
        }}
      />
      <div className="absolute print:relative ml-auto right-0 w-full md:w-1/2 print:md:w-full h-full bg-dark-blue print:bg-white bg-opacity-[.96] z-20">
        <div
          className={classNames("h-full text-white print:text-black", {
            "overflow-y-scroll": !showShareDialogue,
            "overflow-y-hidden": showShareDialogue,
          })}
        >
          <div className="my-10 px-10 md:px-16 max-w-md print:max-w-none">
            <Text variant="h3" className="mb-8 print:!text-xl print:text-black">
              {heading}
            </Text>

            <div className="print:grid print:grid-cols-2 print:gap-x-8">
              <img
                src={productImage?.url}
                className="my-4 hidden print:block print:w-full"
                alt=""
              />

              <div className="my-4 pb-3 flex space-x-10 print:hidden relative">
                <AppLink
                  onClick={() => setShowShareDialogue(true)}
                  aria-label="Share"
                >
                  <span>
                    <Icon name="share" fitHeight className="w-5 h-6 py-0.5" />
                  </span>
                </AppLink>
                <AppLink
                  onClick={async () => {
                    await printSummary();
                  }}
                  aria-label="Print"
                >
                  <span>
                    <Icon name="print" className="w-5 h-6 py-0.5" />
                  </span>
                </AppLink>
                <div
                  className={classNames(
                    "absolute top-12 left-0 !ml-0 bg-white text-black p-2 shadow-md transition duration-200 opacity-0 hidden z-50",
                    { "!opacity-100 !block": showShareDialogue }
                  )}
                  ref={shareDialogue}
                >
                  <Share url={shareUrl} hideShare />
                  <label htmlFor="shareUrl" className="-mt-4 inline-block">
                    URL
                    <input
                      name="shareUrl"
                      type="text"
                      value={shareUrl}
                      id="shareUrl"
                      className="border border-dark-gray p-1 w-full mt-2"
                    />
                  </label>

                  <AppLink
                    onClick={() => setShowShareDialogue(false)}
                    ariaLabe="Close URL share"
                  >
                    <Icon
                      name="close"
                      className="absolute w-3 w-3 top-2 right-2"
                    />
                  </AppLink>
                </div>
              </div>

              <div className="print:mt-2">
                <div className="mb-6">
                  <Text
                    variant="body"
                    className="print:text-black print:!text-xl"
                  >
                    {priceHeading}
                  </Text>
                  <Text
                    variant="h3"
                    className="lg:!text-3xl text-blue print:text-dark-gray print:!text-lg"
                  >
                    {price}
                  </Text>
                  {tradeIn && (
                    <Text
                      variant="xs"
                      className="text-white print:text-dark-gray mt-1"
                    >
                      {`*${tradeIn}`}
                    </Text>
                  )}
                </div>

                {!showFinanceText && (
                  <div className="mb-8">
                    <Text
                      variant="body"
                      className="print:text-black print:!text-xl"
                    >
                      {priceSubheading}
                    </Text>
                    <Text
                      variant="body"
                      className="text-blue print:text-dark-gray print:!text-lg"
                    >
                      {priceSecondary}
                    </Text>
                  </div>
                )}
              </div>
            </div>

            <div className="max-w-[15rem] space-y-4 print:hidden">
              {link2?.url && (
                <Button
                  to={link2?.url}
                  size="lg"
                  className="!py-3.5 first-of-type"
                  fullWidth
                  onClick={() => {
                    setShowShareDialogue(false);
                    dispatch({ type: "closeSummary" });
                  }}
                >
                  <Text>{link2?.text}</Text>
                </Button>
              )}
              {link3?.url && (
                <Button
                  to={link3?.url}
                  size="lg"
                  className="!py-3.5"
                  fullWidth
                  white
                  onClick={() => {
                    setShowShareDialogue(false);
                    dispatch({ type: "closeSummary" });
                  }}
                >
                  <Text>{link3?.text}</Text>
                </Button>
              )}
              {link4?.url && (
                <Button
                  to={link4?.url}
                  size="lg"
                  className="!py-3.5"
                  fullWidth
                  white
                  onClick={() => {
                    setShowShareDialogue(false);
                    dispatch({ type: "closeSummary" });
                  }}
                >
                  <Text>{link4?.text}</Text>
                </Button>
              )}
            </div>

            <div className="my-8 print:hidden">
              <Text variant="body" className="mb-1">
                {contactUsText}
              </Text>
              {linkAnchor?.url && (
                <Button
                  to={linkAnchor?.url}
                  size="md"
                  className="!py-3.5"
                  arrow
                  onClick={() => {
                    setShowShareDialogue(false);
                    dispatch({ type: "closeSummary" });
                  }}
                >
                  <Text className="text-white" variant="sm">
                    {linkAnchor?.text}
                  </Text>
                </Button>
              )}
            </div>

            <div className="print:flex print:grid print:grid-cols-2 print:gap-x-8">
              <div className="mb-6 print:text-black print:mb-8">
                <Text
                  variant="body"
                  className="border-b border-white print:border-light-gray pb-1 mb-1"
                >
                  {receiverHeading}
                </Text>
                <Text
                  variant="xs"
                  className="mt-2 print:text-dark-gray product-summary"
                >
                  {productDescription}
                </Text>
              </div>

              {transmittersDescription && (
                <div className="mb-6 print:text-black print:mb-8">
                  <Text
                    variant="body"
                    className="border-b border-white print:border-light-gray pb-1 mb-1"
                  >
                    {transmitterHeading}
                  </Text>
                  <Text
                    variant="xs"
                    className="mt-2 print:text-dark-gray product-summary"
                  >
                    {transmittersDescription}
                  </Text>
                </div>
              )}

              {displaysDescription && (
                <div className="mb-6 print:text-black print:mb-8">
                  <Text
                    variant="body"
                    className="border-b border-white print:border-light-gray pb-1 mb-1"
                  >
                    {displayHeading}
                  </Text>
                  <Text
                    variant="xs"
                    className="mt-2 print:text-dark-gray product-summary"
                  >
                    {displaysDescription}
                  </Text>
                </div>
              )}

              <div className="mb-6 print:text-black print:mb-8">
                <Text
                  variant="body"
                  className="border-b border-white print:border-light-gray pb-1 mb-1"
                >
                  {accessoriesHeading}
                </Text>
                <ul className="list-disc pl-4 mt-2">
                  {includes?.map(item => {
                    return (
                      <li className="pb-1">
                        <Text variant="xs" className="print:text-dark-gray">
                          {item}
                        </Text>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            {disclosureButtonText && (
              <div className="mb-6 print:hidden">
                <Button
                  type="button"
                  size="md"
                  className="!py-3.5 !bg-transparent uppercase"
                  fullWidth
                  onClick={() => {
                    openModal();
                  }}
                >
                  {disclosureButtonText}
                </Button>
              </div>
            )}
          </div>

          <div className="max-w-3xl lg:max-w-5xl hidden print:block print:max-w-prose w-full print:px-10">
            <div className="py-4 border-b border-light-gray print:text-black">
              <Text variant="body">{disclosureHeading}</Text>
            </div>
            <Text variant="xs" className="block py-4 h-full print:text-black">
              {disclosureCopy}
            </Text>
          </div>
        </div>
        {/* Things go here! */}
        <button
          type="button"
          className={classNames(
            "group absolute top-4 group flex items-center uppercase font-bold text-xs uppercase right-0 z-30 p-6 print:hidden",
            {}
          )}
          onClick={() => {
            setShowShareDialogue(false);
            dispatch({ type: "closeSummary" });
          }}
        >
          {/* <span>Close</span> */}
          <Icon
            name="close"
            className={classNames(
              "w-4 h-4 text-white group-hover:text-blue transform transition duration-200 hover:scale-125 delay-100",
              {}
            )}
          />
        </button>
      </div>
    </section>
  );
};

SummaryDetails.defaultProps = {};

SummaryDetails.propTypes = {};

export default SummaryDetails;
