import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query NewsletterQuery {
    craft {
      globalSets(handle: "newsletter", site: "*") {
        language
        ... on Craft_newsletter_GlobalSet {
          heading
          subHeading
          summary
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const langs = {};
  craft.globalSets.forEach(global => {
    const { heading, subHeading, summary } = global;
    langs[global.language] = {
      heading,
      subHeading,
      thankYouMessage: summary,
    };
  });
  return langs;
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
