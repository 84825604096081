import { useState, useEffect, useRef } from "react";
import debounce from "lodash.debounce";
import isTouchDevice from "./isTouchDevice";

const isIOS = () => {
  return (
    ([
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)) &&
    // eslint-disable-next-line eqeqeq
    navigator.userAgent.search("Firefox") === -1 &&
    navigator.userAgent.search("FxiOS") === -1
  );
};

const hasWindow = typeof window !== "undefined";

const getSize = () => {
  if (hasWindow && isTouchDevice) {
    const swap = isIOS() && window?.orientation !== 0;
    const width = hasWindow
      ? // eslint-disable-next-line no-restricted-globals
        screen?.width ||
        document.documentElement.clientWidth ||
        window.innerWidth
      : 0;
    const height = hasWindow
      ? // eslint-disable-next-line no-restricted-globals
        screen?.height ||
        document.documentElement.clientHeight ||
        window.innerHeight
      : 0;
    return {
      innerHeight: swap ? width : height,
      innerWidth: swap ? height : width,
      outerHeight: hasWindow ? window.outerHeight : 0,
      outerWidth: hasWindow ? window.outerWidth : 0,
      // eslint-disable-next-line no-restricted-globals
      orientation: screen?.orientation?.type || window?.orientation,
    };
  }
  return {
    innerHeight: hasWindow ? window.innerHeight : 0,
    innerWidth: hasWindow ? window.innerWidth : 0,
    outerHeight: hasWindow ? window.outerHeight : 0,
    outerWidth: hasWindow ? window.outerWidth : 0,
  };
};

export function useWindowSize() {
  const resize = useRef(false);

  const ticking = useRef();
  const [windowSize, setWindowSize] = useState(getSize());

  const handleResize = () => {
    if (!hasWindow) {
      return false;
    }

    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        setWindowSize(getSize());
        ticking.current = false;
      });

      ticking.current = true;
    }

    return true;
  };

  const dHandleResize = debounce(handleResize, 200, {
    leading: false,
    trailing: true,
  });

  useEffect(() => {
    if (!resize.current) {
      resize.current = new ResizeObserver(entries => {
        // eslint-disable-next-line no-unused-vars
        for (const entry of entries) {
          dHandleResize();
        }
      });
      resize.current.observe(document.querySelector("#___gatsby"));
    }
  }, []);

  return windowSize;
}

export default useWindowSize;
