import React from "react";
import classNames from "classnames";
import { Text, Container, Image, Button } from "@atoms";

const ImageSpotlight = ({
  label,
  content,
  image,
  button,
  gradient,
  textPosition,
}) => {
  return (
    <section className="relative">
      <Container
        variant="md"
        className="relative z-50 py-20 md:py-32 flex md:grid grid-cols-2 gap-8 md:gap-1 justify-items-stretch "
      >
        <div
          className={classNames(
            "max-w-none md:max-w-md px-0 md:px-4",
            { "mr-0 md:mr-auto": textPosition === "left" },
            {
              "ml-0 md:ml-auto order-last": textPosition === "right",
            }
          )}
        >
          <Text variant="sm" className="text-blue mb-6 font-semibold">
            {label}
          </Text>
          <Text variant="xl" className="my-6 font-bold">
            {content}
          </Text>
          {button && (
            <Button size="lg" to={button?.url} border>
              <Text variant="body" className="!leading-none">
                {button?.text}
              </Text>
            </Button>
          )}
        </div>
        {image && (
          <div className="hidden md:block w-full max-w-[33.75rem]">
            <Image {...image} />
          </div>
        )}
      </Container>

      {gradient && (
        <div className="w-full h-full absolute inset-0 z-0 bg-gradient-to-t from-silver opacity-40" />
      )}
    </section>
  );
};

ImageSpotlight.defaultProps = {
  textPosition: "left",
};

export default ImageSpotlight;
