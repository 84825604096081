import translations from "../translations/index";

const useT = (string, lang = "en") => {
  const translation = translations?.[lang]?.[string];
  if (translation) {
    return translation;
  }
  return string;
};

export default useT;
