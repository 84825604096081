import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { DropDown, Text } from "@atoms";
import { t } from "@utils";
import { useAppState } from "@state/state";

const FilterInsights = ({ model, options: _options }) => {
  const [{ translation }] = useAppState();
  const { lang } = translation;
  const options = {
    types: [
      t("All Resources", lang),
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...Object?.keys(_options?.insights).map(key => _options.insights[key]),
    ],
  };
  const [type, setType] = useState(0);
  const [, setState] = model;

  useEffect(() => {
    setState({
      type,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <div className="flex space-x-4 mb-5">
      <div className="flex items-center">
        <Text variant="lg" className="mr-4">
          {t("Showing", lang)}
        </Text>
        {/* create a dropdown for each category to be filtered */}
        <DropDown
          options={options?.types}
          model={[type, setType]}
          placeholder={false}
          iconSize={3}
          sans
        />
      </div>
    </div>
  );
};

FilterInsights.defaultProps = {};

FilterInsights.propTypes = {
  model: propTypes.arrayOf(
    propTypes.oneOfType([propTypes.object, propTypes.func])
  ).isRequired,
};

export default FilterInsights;
