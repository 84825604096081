import React from "react";
import { Text, Image } from "@atoms";
import { AppLink } from "@base";

const AboveFooterCta = ({ title, subtitle, image, url, eager }) => {
  return (
    <div className="w-full md:w-1/2">
      <AppLink
        to={url}
        className="!block text-white w-full group overflow-hidden"
      >
        <div className="relative w-full h-full">
          <div className="transform transition duration-200 group-hover:scale-105">
            <Image {...image} eager={eager} />
          </div>

          <div className="text-white text-center absolute z-10 w-full bottom-0 py-8 px-6 bg-gradient-bottom">
            <Text variant="h3" className="">
              {title}
            </Text>
            {subtitle && (
              <Text variant="md" className="block mt-6 mb-0">
                {subtitle}
              </Text>
            )}
          </div>
        </div>
      </AppLink>
    </div>
  );
};

AboveFooterCta.defaultProps = {};

AboveFooterCta.propTypes = {};

export default AboveFooterCta;
