const resolvePackage = array => {
  if (array?.length > 0) {
    return array?.map(p => {
      const options = p?.options?.reduce((allOptions, o) => {
        return {
          ...allOptions,
          [o.typeHandle]: o,
        };
      }, {});

      return {
        ...p,
        // Just making sure that the transmitter is first in the array
        // for easier checking of uids
        options,
        additionalIncludes: p?.additionalIncludes?.map(inc => {
          return inc?.info;
        }),
      };
    });
  }
  return null;
};

export default resolvePackage;
