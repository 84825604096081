import React, { useEffect, useState } from "react";
import { Container, Text, Icon } from "@atoms";
import { useJobData } from "@staticQueries";
import Departments from "./Departments";

const resolveJobs = ({ jobs }) => {
  const extractCats = jobs.map(job => job?.category);
  const categories = extractCats?.filter(
    (cat, i) => extractCats.indexOf(cat) === i
  );
  return {
    jobs,
    filteredJobs: categories?.map(category => {
      return {
        name: category,
        jobs: jobs?.filter(job => category === job?.category),
      };
    }),
  };
};

const CbJobPostings = ({
  heading,
  subheading,
  footer,
  descriptionDefault,
  scrollText,
}) => {
  const { filteredJobs } = useJobData();
  const [jobs, setJobs] = useState(filteredJobs);

  useEffect(() => {
    fetch("/api/get-jobs")
      .then(res => res.json())
      .then(res => {
        const _jobs = resolveJobs(res);
        setJobs(_jobs.filteredJobs);
      });
  }, []);

  return (
    <section className="py-16 bg-light-gray">
      <Container variant="xl">
        <div className="px-0 md:px-8 grid grid-cols-1 md:grid-cols-2 mb-6">
          <div className="mb-12 max-w-xs">
            <Text variant="h2">{heading}</Text>
            <Text
              variant="body"
              className="mt-6 text-dark-gray max-w-2xl mx-auto"
            >
              {subheading}
            </Text>
          </div>
          <div className="mb-auto relative">
            <div className="hidden md:block bg-gradient-to-t from-light-gray via-light-gray absolute z-40 bottom-0 inset-x-0 w-full h-32 pointer-events-none" />
            <div className="max-w-none md:max-h-[40rem] overflow-y-auto thin-scrollbar pb-0 md:pb-36">
              {jobs?.map((department, i) => {
                return (
                  <Departments
                    {...department}
                    descriptionDefault={descriptionDefault}
                  />
                );
              })}
            </div>
            <div className="hidden md:flex flex-row relative p-2 z-50 items-center justify-center space-x-2 text-blue">
              <Text variant="xs">{scrollText}</Text>
              <Icon name="mouse" className="w-4 h-4" />
            </div>
          </div>
        </div>
        <div className="px-0 md:px-8">
          <Text variant="body" className="mx-auto text-center">
            {footer}
          </Text>
        </div>
      </Container>
    </section>
  );
};

CbJobPostings.defaultProps = {};

export default CbJobPostings;
