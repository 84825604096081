import React from "react";
import classNames from "classnames";
import { Text, CbWrapper, Container } from "@atoms";

const CbBlockQuote = ({ quote, attribution, type, blog }) => {
  return (
    <Container variant="lg" interior={blog} padding={blog}>
      <CbWrapper type={type}>
        <div className={classNames("max-w-3xl", { "mx-auto": !blog })}>
          <blockquote
            className={classNames("w-full", {
              "border-t-2 border-b-2 pt-4 pb-8": blog,
            })}
          >
            <Text
              variant="2xl"
              className={classNames(
                "my-4",
                { "italic text-blue": !blog },
                { "text-black lg:text-3xl": blog }
              )}
            >
              {`"${quote}"`}
            </Text>
            <Text
              variant="body"
              className={classNames(
                "tracking-wider uppercase font-bold",
                { "text-dark-gray": !blog },
                { "text-blue lg:text-xl mt-6": blog }
              )}
            >
              {`${blog ? "" : "–"}${attribution}`}
            </Text>
          </blockquote>
        </div>
      </CbWrapper>
    </Container>
  );
};

CbBlockQuote.defaultProps = {};

CbBlockQuote.propTypes = {};

export default CbBlockQuote;
