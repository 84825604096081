import React from "react";
import Text from "./Text";

const CbWrapper = ({ indent: _indent, count, children, margin, type }) => {
  let indent = _indent;
  if (count) {
    indent = true;
  }
  const marginValues = {
    removeTop: "mb-10 -mt-6",
    topOnly: "mt-10",
    bottomOnly: "mb-10",
    default: "my-10",
  };
  return (
    <div
      className={`content-builder__${type} relative ${indent ? "pl-6" : ""} ${
        marginValues[margin]
      }`}
    >
      {count && (
        <div className="absolute top-0 left-0 w-4 text-red font-bold font-serif">
          <Text variant="body--large-tight">{`${count}.`} </Text>
        </div>
      )}
      {children}
    </div>
  );
};

CbWrapper.defaultProps = {
  margin: "default",
  indent: false,
};

CbWrapper.propTypes = {};

export default CbWrapper;
