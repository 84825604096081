import React from "react";
import propTypes from "prop-types";
import { AppLink } from "@base";
import { Icon, Text } from "@atoms";
import classNames from "classnames";

const Button = ({
  color,
  to,
  title,
  type,
  onClick,
  linkObj: LinkObj,
  children,
  fullWidth,
  size,
  rounded,
  arrow,
  small,
  ariaLabel,
  transmitterActive,
  className: _className,
  white,
  border,
}) => {
  let classes = `tracking-wider inline-flex justify-center items-center cursor-pointer items-center relative z-0 opacity-100 hover:opacity-50 group leading-tighter transition duration-200`;

  // set size
  switch (size) {
    case "fluid":
      classes += " p-2 text-xs";
      break;
    case "xxs":
      classes += " p-2 text-xs";
      break;
    case "xs":
      classes += " px-5 py-2 text-xxs";
      break;
    case "md":
      classes += " px-4 py-3 text-xs";
      break;
    case "lg":
      classes += " px-8 py-5 text-base";
      break;
    case "sm":
      classes += " py-3 px-6 text-xs";
      break;
    default:
      classes += " py-3 px-4 text-sm";
      break;
  }

  if (rounded) {
    classes += " rounded-full";
  }

  if (fullWidth) {
    classes += " w-full";
  }

  if (white) {
    classes += " text-dark-gray bg-white";
  } else if (border) {
    classes += " bg-transparent border-2 border-blue";
  } else {
    classes += " text-white bg-blue";
  }

  if (to && to.length > 1 && arrow) {
    return (
      <LinkObj
        to={to}
        title={title}
        onClick={onClick}
        className="self-start group text-black hover:text-blue duration-200 transition-colors uppercase font-bold"
        arial-label={ariaLabel}
      >
        <div className="flex flex-row items-center">
          <Text variant="body" className="tracking-wider">
            {children}
          </Text>
          <Icon
            name="arrowRight"
            className={classNames(
              "text-blue fill-blue transform group-hover:scale-125",
              { "w-1.5 ml-2": small },
              { "w-3 ml-4": !small }
            )}
          />
        </div>
      </LinkObj>
    );
  }
  if (to && to.length > 1) {
    return (
      <LinkObj
        to={to}
        title={title}
        className={`${classes} ${_className}`}
        onClick={onClick}
      >
        <span className="flex relative z-10 text-center">{children}</span>
      </LinkObj>
    );
  }
  if (arrow) {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        title={title}
        onClick={onClick}
        className="self-start group text-black hover:text-blue duration-200 transition-colors uppercase font-bold"
        arial-label={ariaLabel}
      >
        <div className="flex flex-row items-center">
          <Text variant="body" className="tracking-wider">
            {children}
          </Text>
          <Icon
            name="arrowRight"
            className={classNames(
              "text-blue fill-blue transform group-hover:scale-125 transition duration-300 ease-in-out ",
              { "w-1.5 ml-2": small },
              { "w-3 ml-4": !small },
              { "rotate-90 group-hover:scale-100": transmitterActive }
            )}
          />
        </div>
      </button>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`${classes} ${_className}`}
      onClick={onClick}
    >
      <span className="flex relative z-10 text-center">{children}</span>
    </button>
  );
};

Button.defaultProps = {
  linkObj: AppLink,
  color: "red",
  title: null,
  type: "button",
  onClick: null,
  size: "default",
  to: null,
};

Button.propTypes = {
  linkObj: propTypes.func,
  color: propTypes.string,
  to: propTypes.string,
  title: propTypes.string,
  type: propTypes.string,
  onClick: propTypes.func,
  size: propTypes.string,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default Button;
