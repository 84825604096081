import React from "react";
import classNames from "classnames";
import { Text, Image, CbWrapper, Container } from "@atoms";

const CbImage = ({ image, caption, type, blog }) => {
  return (
    <Container variant="lg" interior={blog} padding={blog}>
      <CbWrapper type={type}>
        <div
          className={classNames("max-w-5xl h-full py-16", { "mx-auto": !blog })}
        >
          <Image {...image} />
          <Text>{caption}</Text>
        </div>
      </CbWrapper>
    </Container>
  );
};

CbImage.defaultProps = {};

CbImage.propTypes = {};

export default CbImage;
