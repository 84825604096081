import React from "react";

const FormContainer = ({ children, onSubmit, className: _className }) => {
  return (
    <div className={_className}>
      <form onSubmit={onSubmit}>
        <div className="grid grid-cols-2 gap-x-16 gap-y-8">{children}</div>
      </form>
    </div>
  );
};

FormContainer.defaultProps = {};

FormContainer.propTypes = {};

export default FormContainer;
