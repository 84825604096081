import React from "react";
import classNames from "classnames";
import { Icon, Text } from "@atoms";

const IconListItem = ({ icon, iconOverride, iconOverride2, heading, blue }) => {
  return (
    <li className="flex flex-row items-center mb-4 pb-1">
      <Icon
        name={iconOverride || icon}
        override={iconOverride2?.[0]?.svgCode}
        className={classNames(
          "w-10 mr-4",
          { "text-white fill-white": !blue },
          { "text-blue fill-blue": blue }
        )}
      />
      <Text variant="body" className="my-4">
        {heading}
      </Text>
    </li>
  );
};

IconListItem.defaultProps = {};

IconListItem.propTypes = {};

export default IconListItem;
