import React from "react";
import { Container, FormContainer, Field, Button, Text } from "@atoms";

const FormCaseStudy = ({ thanksMessage, recipients }) => {
  const onSubmit = e => {
    // Form things go here
  };

  return (
    <section>
      <Container variant="xl">
        <FormContainer onSubmit={onSubmit}>
          <Field label="Name" name="name" required type="text" cols={1} light />
          <Field
            label="Email"
            name="email"
            required
            type="email"
            cols={1}
            light
          />
          <Field
            label="Company"
            name="company"
            required
            type="text"
            cols={1}
            light
          />
          <Field label="URL" name="url" type="url" cols={1} light />
          <Field label="Message" name="url" textarea cols={2} light />
          <Field
            label="Upload images"
            name="attachments[]"
            type="file"
            cols={2}
            light
            singleLine
          />
          <Text variant="sm" className="text-dark-gray col-span-full">
            To upload multiple photos, hold shift while selecting the files
          </Text>
          <div className="text-center col-span-full">
            <Button type="submit" size="lg">
              <Text>Submit</Text>
            </Button>
          </div>
        </FormContainer>
      </Container>
    </section>
  );
};

FormCaseStudy.defaultProps = {};

FormCaseStudy.propTypes = {};

export default FormCaseStudy;
