const convertToMoney = (price, options) => {
  const { decimals, format, currency } = options || {};

  return new Intl.NumberFormat(format || "en-US", {
    style: "currency",
    currency: currency || "USD",
    minimumFractionDigits: decimals || 0,
    maximumFractionDigits: decimals || 0,
  }).format(price);
};

export default convertToMoney;
