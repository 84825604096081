import React, { useRef, useEffect } from "react";
import { AppLink } from "@base";
import { Text, MobileHeaderDropdown } from "@atoms";
import { useHeaderData } from "@staticQueries";
import gsap from "gsap";
import { useAppState } from "@state/state";
import { toggleBodyScroll } from "@utils";

const MobileHeader = () => {
  const mobileContainer = useRef();

  // Mobile nav triggers
  const [{ layout, translation }, dispatch] = useAppState();
  const { showNav } = layout;
  const { lang } = translation;
  const data = useHeaderData();
  const { center, right } = data[lang || "en"];

  useEffect(() => {
    if (showNav) {
      gsap.to(mobileContainer.current, {
        height: "auto",
        duration: 0.3,
        onComplete: () => toggleBodyScroll(false),
      });
    } else {
      gsap.to(mobileContainer.current, {
        height: 0,
        duration: 0.3,
        onComplete: () => toggleBodyScroll(true),
      });
    }
  }, [showNav]);

  return (
    <div
      className="overflow-hidden h-0 max-h-[calc(100vh-7rem)] overflow-y-scroll lg:hidden"
      ref={mobileContainer}
    >
      {center?.map((sub, i) => {
        return (
          <MobileHeaderDropdown {...sub} key={`mobileSubHeader--${i + 1}`} />
        );
      })}

      {right?.map((link, i) => {
        return (
          <div className="w-full bg-white" key={`mobileHeader--${i + 1}`}>
            <AppLink
              to={link?.url}
              className="block p-4 text-center group"
              onClick={() => {
                dispatch({
                  type: "hideNav",
                });
              }}
            >
              <Text
                variant="body"
                className="text-black group-hover:text-blue uppercase font-bold tracking-widest"
              >
                {link?.text}
              </Text>
            </AppLink>
          </div>
        );
      })}
    </div>
  );
};

MobileHeader.defaultProps = {};

MobileHeader.propTypes = {};

export default MobileHeader;
