import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { useGdprData } from "@staticQueries";

import { Button, Icon, Text } from "@atoms";
import { AppLink } from "@base";
import { loadGTM, gdpr as _gdpr } from "@utils";

import gsap from "gsap";

const GdprBanner = () => {
  const gdprCookieName = process.env.GATSBY_APP_NAME;
  const { hasGdprConsent } = _gdpr;

  const [gtmLoaded, setGtmLoaded] = useState(false);
  const [close, setClose] = useState(false);
  const dataLayer = typeof window !== "undefined" ? window.dataLayer || [] : [];
  const banner = useRef();
  const tl = useRef();

  const [{ layout, translation }, dispatch] = useAppState();
  const { lang } = translation;
  const { gdpr } = layout;
  const data = useGdprData();
  const { copy, buttonText } = data[lang || "en"];

  const setDoNotTrack = state => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("doNotTrack", state);
      setClose(state);
    }
  };

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true });
    tl.current = gsap.to(banner.current, 1.75, {
      y: "0%",
      opacity: 1,
      ease: "power4.inOut",
    });
    if (!gtmLoaded) {
      loadGTM(gdpr);
      setGtmLoaded(true);
    }
    if (typeof window !== "undefined") {
      if (
        window.localStorage.getItem("doNotTrack") ||
        hasGdprConsent(gdprCookieName)
      ) {
        setClose(true);
      }
    }
  }, []);

  // Show/Hide the gdpr banner based on gdpr acceptance
  useEffect(() => {
    if (gdpr) {
      tl.current.reverse();
      if (!dataLayer.find(v => v.gdprAccepted === true)) {
        dataLayer.push({
          event: "gdpr-accepted", // used to trigger other events in GTM, namely loading Hubspot and Google Analytics
          gdprAccepted: true, // used by other triggers to determine if they're allowed to run
          doNotTrack: window.localStorage.getItem("doNotTrack"), // used to filter out internal Cosmic traffic
        });
      }
    } else {
      tl.current.play();
    }
  }, [gdpr]);

  useEffect(() => {
    if (close) {
      tl.current.reverse();
    } else {
      tl.current.play();
    }
  }, [close]);

  return (
    <div
      ref={banner}
      className={classNames(
        "fixed bottom-0 left-0 right-0 my-4 w-full transform translate-y-[200%] opacity-0 z-[100] pointer-events-none px-4 md:px-8 print:hidden",
        { "pointer-events-none": gdpr || close },
        { "pointer-events-auto": !gdpr || !close }
      )}
    >
      <div className="flex flex-col md:flex-row space-x-0 md:space-x-4 justify-end items-center px-4 py-6 bg-blue shadow-md max-w-2xl ml-auto">
        <div className="flex flex-col max-w-2xl mb-4 md:mb-0 text-white">
          <Text variant="sm" className="leading-tight">
            {copy}
          </Text>
        </div>
        <div className="items-center w-full md:w-auto justify-between md:justify-center flex flex-nowrap flex-shrink-0">
          <Button
            size="sm"
            onClick={() => dispatch({ type: "acceptGdpr" })}
            white
          >
            <Text variant="sm">{buttonText}</Text>
          </Button>
          <AppLink
            className="ml-4 pr-4 md:pr-0"
            transparent
            noBottomPadding
            onClick={() => setDoNotTrack(true)}
          >
            <Icon
              name="close"
              className="text-white w-4 h-4 md:w-3 md:h-3"
              fitHeight
            />
          </AppLink>
        </div>
      </div>
    </div>
  );
};

export default GdprBanner;
