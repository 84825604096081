// spanish translations
export default {
  Name: "Name",
  ID: "ID",
  Color: "Color",
  Price: "Price",
  "Learn More": "Learn More",
  "Play video": "Play video",
  "View the transmitters for": "View the transmitters for",
  "Select one": "Select one",
  "All Resources": "All Resources",
  Showing: "Showing",
  "Job Title": "Job Title",
  Company: "Company",
  Email: "Email",
  "You have already been added to the newsletter list.":
    "You have already been added to the newsletter list.",
  "Please fill out all required fields": "Please fill out all required fields",
  Submit: "Submit",
  "PDF Downloads": "PDF Downloads",
  Videos: "Videos",
  "Related Articles": "Related Articles",
  Share: "Share",
  "Load More Articles": "Load More Articles",
  "Read Article": "Read Article",
  "Next Article": "Next Article",
  "Next Event": "Next Event",
  Explore: "Explore",
  "Read More": "Read More",
  Includes: "Includes",
  "Play Video": "Play Video",
};
