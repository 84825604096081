import React from "react";
import classNames from "classnames";
import { Container, Text } from "@atoms";
import { convertToMoney } from "@utils";

const ComponentAccessories = ({
  heading,
  subheading,
  componentsAccessories,
}) => {
  return (
    <section className={classNames("mt-2 text-dark-gray bg-light-gray py-16")}>
      <Container variant="xl">
        <div className="text-center mb-12">
          <Text variant="h2">{heading}</Text>
          <Text variant="body" className="mt-6 text-dark-gray">
            {subheading}
          </Text>
        </div>
        {componentsAccessories?.length > 0 && (
          <div className="max-w-2xl mx-auto">
            {componentsAccessories?.map((accessories, i) => {
              return (
                <div
                  className={classNames(
                    "flex justify-between flex-nowrap p-4",
                    { "bg-near-white": i % 2 !== 0 },
                    {
                      "border-b border-light-silver":
                        i !== (componentsAccessories?.length || 0) - 1,
                    }
                  )}
                >
                  <div className="pr-2">
                    <Text variant="lg">{accessories?.title}</Text>
                  </div>
                  <div className="">
                    <Text variant="lg">
                      {convertToMoney(accessories?.price)}
                    </Text>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Container>
    </section>
  );
};

ComponentAccessories.defaultProps = {};

ComponentAccessories.propTypes = {};

export default ComponentAccessories;
