import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import gsap from "gsap";
import { Icon, Text } from "@atoms";
// import { uid } from "react-uid";
import { t } from "@utils";
import { useAppState } from "@state/state";

const Dropdown = ({
  model,
  options,
  placeholder,
  inverse,
  size,
  sans,
  transparent,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = model;
  const dropdown = useRef();
  const tl = useRef();
  const arrow = useRef();
  const [{ translation }] = useAppState();
  const { lang } = translation;

  const handleClickOutside = event => {
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    setIsActive(false);
  }, [value]);

  useEffect(() => {
    tl.current = gsap
      .timeline()
      .fromTo(
        dropdown.current,
        { height: 0 },
        { height: "auto", duration: 0.25, ease: "power1.in" }
      )
      .fromTo(
        arrow.current,
        { rotate: 0, y: 0 },
        { rotate: -90, y: 2, duration: 0.25, ease: "power1.in" },
        "=-0.25"
      );
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      tl.current.kill();
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (tl.current) {
      if (isActive) {
        tl.current.play();
      } else {
        tl.current.reverse();
      }
    }
  }, [isActive]);

  // eslint-disable-next-line global-require

  let iconSize;

  switch (size) {
    case "xs":
      iconSize = "1";
      break;
    case "sm":
      iconSize = "2";
      break;
    case "md":
      iconSize = "3";
      break;
    case "lg":
      iconSize = "4";
      break;
    default:
      iconSize = "3";
      break;
  }

  let bgColor = "white";
  let textColor = "black";
  let dropDownColor = "blue";

  if (inverse) {
    bgColor = "black";
    textColor = "white";
    dropDownColor = "red";
  }

  return (
    <div className="relative border-light-silver">
      <button
        type="button"
        onClick={() => setIsActive(!isActive)}
        className={`flex items-center text-${size} text-${textColor} justify-between ${
          size === "xs" ? "px-1" : "px-4 py-2"
        } w-full ${sans ? "" : ""} 
          ${inverse ? "border-red" : ""}
          ${
            transparent
              ? "bg-transparent"
              : "bg-light-gray border border-light-silver"
          }`}
      >
        <span className="text-left max-w-xs truncate mr-2">
          <Text variant={size === "xs" ? "xs" : "lg"}>
            {value != null && value !== ""
              ? options[value]
              : placeholder || t("Select One", lang) || options[0]}
          </Text>
        </span>
        {/* dropdown Icon */}
        <span
          ref={arrow}
          className={`icon w-${iconSize} block h-${iconSize} pl-1 text-${dropDownColor}`}
        >
          <Icon
            name="triangle"
            className={size === "xs" ? "w-2 h-2" : "w-3 h-3"}
            fitHeight
          />
        </span>
      </button>
      <div className="-mt-px relative border-light-silver">
        <ul
          ref={dropdown}
          className={`absolute min-w-[3rem] z-40 left-0 text-${size} bg-${bgColor} text-${textColor}
           ${inverse ? "" : ""}
           right-0 h-0 overflow-hidden
           ${isActive ? "" : "shadow-none"}`}
        >
          {/*          {placeholder.length > 1 && (
            <li>
              <button
                type="button"
                className={`w-full px-4 py-2 text-${size} text-left border border-light-silver px-4 py-2 bg-light-gray`}
                disabled
              >
                <Text variant="lg">{placeholder}</Text>
              </button>
            </li>
          )} */}
          {options.map((option, i) => {
            return (
              <li key={option?.uid ? option?.uid : option + String(i)}>
                <button
                  onClick={() => setValue(i)}
                  type="button"
                  className={classNames(
                    "w-full px-4 py-2 text-left transition duration-200 border-b border-r border-l border-light-silver bg-light-gray hover:bg-white hover:text-blue px-4 py-2",
                    { "border-t": i === 0 },
                    { "!bg-white !text-blue": value === i }
                  )}
                >
                  <Text variant={size === "xs" ? "xs" : "lg"}>{option}</Text>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  inverse: false,
  size: "md",
  blue: false,
};

export default Dropdown;
