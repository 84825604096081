// spanish translations
export default {
  Name: "Nombre",
  ID: "ID",
  Color: "Color",
  Price: "Precio",
  "Learn More": "Conozca más",
  "Play video": "Reproducir vídeo",
  "View the transmitters for": "Ver los transmisores para",
  "Select one": "Seleccionar uno",
  "All Resources": "Todos los recursos",
  Showing: "Exposición",
  "Job Title": "Título del trabajo",
  Company: "Compañía",
  Email: "Correo electrónico",
  "You have already been added to the newsletter list.":
    "Ya ha sido añadido a la lista del boletín.",
  "Please fill out all required fields":
    "Rellene todos los campos obligatorios",
  Submit: "Sometió",
  "PDF Downloads": "Descargas en PDF",
  Videos: "Vídeos",
  "Related Articles": "Artículos relacionados",
  Share: "Compartir",
  "Load More Articles": "Cargar más artículos",
  "Read Article": "Leer el artículo",
  "Next Article": "Artículo siguiente",
  "Next Event": "Próximo evento",
  Explore: "Explorar",
  "Read More": "Leer más",
  Includes: "Incluye",
  "Search articles": "Buscar artículos",
  "Play Video": "Reproducir vídeo",
};
