import React from "react";
import { Helmet } from "react-helmet";
import propTypes from "prop-types";
import { SummaryDetails, GdprBanner, PreviewBanner } from "@molecules";
import loadable from "@loadable/component";

const Modal = loadable(() => import("@organisms"), {
  resolveComponent: components => components.Modal,
});

const Header = loadable(() => import("@organisms"), {
  resolveComponent: components => components.Header,
});

const DefaultLayout = ({ children, path, pageContext }) => {
  const { language: lang } = pageContext;

  return (
    <>
      <div id="app_layout">
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />

          <html lang={lang} />

          {lang !== "zh" && (
            <link
              rel="preload"
              as="style"
              href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap"
            />
          )}
          {lang !== "zh" && (
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap"
            />
          )}

          {lang === "zh" && (
            <link
              rel="preload"
              as="style"
              href="https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;700&display=swap"
            />
          )}
          {lang === "zh" && (
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;700&display=swap"
            />
          )}
        </Helmet>
        <Header lang={lang} />
        {children}
      </div>
      <SummaryDetails />
      <Modal />
      <GdprBanner />
      <PreviewBanner />
    </>
  );
};

DefaultLayout.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
  path: propTypes.string.isRequired,
};

export default DefaultLayout;
