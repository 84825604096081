import React from "react";
import { Text, Image, Button } from "@atoms";
import { t } from "@utils";
import { useAppState } from "@state/state";

const CarouselSlide = ({
  title,
  heroTitle,
  heading,
  metaDescription,
  summary,
  metaFeaturedImage,
  url,
  linkIt,
}) => {
  const [{ translation }] = useAppState();
  const { lang } = translation;

  return (
    <div className="w-full md:w-auto max-w-none md:max-w-[73rem] mx-auto flex flex-col md:flex-row items-start md:items-center">
      <div className="mb-4 w-full md:w-1/2 px-4 pointer-events-none">
        <Image {...metaFeaturedImage} fitHeight />
      </div>

      <div className="mx-4 w-full md:w-1/2">
        <Text
          variant="h3"
          className="md:!text-3xl pointer-events-none pr-8 md:pr-0"
        >
          {heading || heroTitle || title}
        </Text>
        <Text variant="body" className="pr-8 md:pr-0 my-4 pointer-events-none">
          {summary || metaDescription}
        </Text>
        {url && (
          <Button arrow to={url}>
            {t("Learn More", lang)}
          </Button>
        )}
        {linkIt?.url && (
          <Button arrow to={linkIt?.url}>
            {linkIt?.text}
          </Button>
        )}
      </div>
    </div>
  );
};

CarouselSlide.defaultProps = {};

CarouselSlide.propTypes = {};

export default CarouselSlide;
