export default {
  Name: "Name",
  ID: "ID",
  Color: "Farbe",
  Price: "Preis",
  "Learn More": "Erfahren Sie mehr",
  "Play video": "Video abspielen",
  "View the transmitters for": "Sender anzeigen für",
  "Select one": "Wähle eins",
  "All Resources": "Alle Ressourcen",
  Showing: "Zeigen",
  "Job Title": "Berufsbezeichnung",
  Company: "Gesellschaft",
  Email: "Email",
  "You have already been added to the newsletter list.":
    "Sie wurden bereits zur Newsletter-Liste hinzugefügt.",
  "Please fill out all required fields":
    "Bitte füllen Sie alle erforderlichen Felder aus",
  Submit: "Einreichen",
  "PDF Downloads": "PDF-Downloads",
  Videos: "Videos",
  "Related Articles": "Zum Thema passende Artikel",
  Share: "Teilen",
  "Load More Articles": "Weitere Artikel laden",
  "Read Article": "Artikel lesen",
  "Next Article": "Nächster Artikel",
  "Next Event": "Nächste Veranstaltung",
  Explore: "Entdecken",
  "Read More": "Weiterlesen",
  Includes: "Beinhaltet",
  "Search articles": "Artikel suchen",
  "Play Video": "Video abspielen",
};
