const reducer = (state, action) => {
  switch (action.type) {
    case "openSummary":
      return {
        ...state,
        open: true,
      };
    case "closeSummary":
      return {
        ...state,
        open: false,
      };
    case "clearSummary":
      return {
        ...state,
        open: false,
        content: false,
      };
    case "updateSummary":
      return {
        ...state,
        content: action.content,
      };
    default:
      return state;
  }
};

const initialState = {
  open: false,
  content: false,
};

const layout = {
  reducer,
  initialState,
};

export default layout;
