import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query JobsQuery {
    allJob(sort: { order: ASC, fields: [category, title] }) {
      nodes {
        location
        locationCountry
        applyLink
        detailLink
        department
        detailLink
        eId
        title
        category
      }
    }
  }
`;

const dataResolver = ({ allJob }) => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const jobs = allJob?.nodes;
  const extractCats = allJob?.nodes?.map(job => job?.category);
  const categories = extractCats?.filter(
    (cat, i) => extractCats.indexOf(cat) === i
  );

  return {
    jobs,
    filteredJobs: categories?.map(category => {
      return {
        name: category,
        jobs: jobs?.filter(job => category === job?.category),
      };
    }),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
