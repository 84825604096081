import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const toggleBodyScroll = (enable, element = document.body) => {
  if (enable) {
    enableBodyScroll(element);
  } else {
    disableBodyScroll(element);
  }
};

export default toggleBodyScroll;
