import React from "react";
import classNames from "classnames";
import { Image, Text } from "@atoms";
import { Carousel } from "@molecules";

const CbQuotes = ({ quotes, imageTop, imageBottom, carouselPosition }) => {
  return (
    <section className="flex flex-col lg:flex-row mt-2">
      <div
        className={classNames(
          "w-full lg:w-2/3 bg-light-gray mx-0 py-6 items-stretch",
          { "lg:mr-2": carouselPosition === "left" },
          { "lg:ml-2": carouselPosition === "right" }
        )}
      >
        <Carousel
          maxVisible={1}
          showIndicators
          bottomIndicators
          centerItems
          className="h-full overflow-hidden"
        >
          {quotes?.map((quote, i) => {
            const { copy, image, attribution } = quote || {};
            return (
              <div className="flex flex-col items-center justify-center">
                {image && (
                  <div className="w-full max-w-[16rem] mb-2 px-8 pointer-events-none">
                    <div className="rounded-full overflow-hidden">
                      <Image {...image} />
                    </div>
                  </div>
                )}
                <blockquote className="text-center w-full max-w-xl mx-auto p-4">
                  <Text
                    variant="xl"
                    className="my-4 italic"
                  >{`&ldquo;${copy}&rdquo;`}</Text>
                  <Text variant="sm" className="text-heading">
                    {attribution}
                  </Text>
                </blockquote>
              </div>
            );
          })}
        </Carousel>
      </div>

      <div
        className={classNames(
          "flex flex-row lg:flex-col w-full lg:w-1/3",
          { "order-last": carouselPosition === "left" },
          { "order-last lg:order-first": carouselPosition === "right" }
        )}
      >
        <div className="w-full h-[calc(62.5vw/2)] md:h-1/2 mr-1 lg:mr-0 mb-0 lg:mb-1 relative">
          <Image {...imageTop} fill />
        </div>
        <div className="w-full h-[calc(62.5vw/2)] md:h-1/2 ml-1 lg:ml-0 mt-0 lg:mt-1 relative">
          <Image {...imageBottom} fill />
        </div>
      </div>
    </section>
  );
};

CbQuotes.defaultProps = {};

CbQuotes.propTypes = {};

export default CbQuotes;
