import React from "react";
import propTypes from "prop-types";
import { useIconData } from "@staticQueries";

const Icon = ({
  name,
  className: _className,
  fitHeight,
  override,
  noSanitize,
}) => {
  const { icons: _icons } = useIconData();
  const foundIcon = _icons?.map?.(i => i?.iconName).indexOf(name);

  const icons = {
    "case-studies": "caseStudies",
    "chat-bubbles": "chatBubbles",
    company: "company",
    crosshair: "crosshair",
    "hard-hat": "hardHat",
    truck: "truck",
    igps: "igps",
    "frequency-analyzer": "frequencyAnalyzer",
    "frequency-optimization": "frequencyOptimization",
    "max-mode": "maxMode",
    fluidpressure: "fluidPressure",
    pairing: "pairing",
    rebar: "rebar",
    "roll-positions": "rollPositions",
    targeting: "targeting",
    bonuses: "bonuses",
    celebrations: "celebrations",
    commuter: "commuter",
    dogFriendly: "dogFriendly",
    gamer: "gamer",
    lifeInsurance: "lifeInsurance",
    medical: "medical",
    retirement: "retirement",
    sports: "sports",
    tickets: "tickets",
    tuition: "tuition",
    wellness: "wellness",
    workLifeBalance: "workLifeBalance",
    ddm: "ddm",
    lwd: "lwd",
    digitrak: "digitrak",
    transmitters: "transmitters",
    display: "display",
    receiver: "receiver",
    "receiver-f1": "receiverF1",
    "receiver-f2": "receiverF2",
    "receiver-f5": "receiverF5",
    "fluid pressure": "fluidPressure",
    "19''": "nineteen",
    "24clock": "twentyFour",
    "increased depth": "increasedDepth",
    increaseddepth: "increasedDepth",
    "dual power": "dualPower",
    "scans over": "scansOver",
    "use with": "useWith",
    "with the": "withThe",
    "simple user": "simpleUser",
    withthe: "withThe",
    simpleuser: "simpleUser",
    "faster data rates": "fasterDataRates",
    fasterdatarates: "fasterDataRates",
    "multi power": "multiPower",
    multipower: "multiPower",
    "sun mountain": "sunMountain",
    "risk free": "riskFree",
    "social-wechat": "wechat",
    "double arrow": "doubleArrow",
    "social-youtube": "socialYoutube",
    "social-twitter": "socialTwitter",
    "social-instagram": "socialInstagram",
    "social-facebook": "socialFacebook",
    "social-snapchat": "socialSnapchat",
  };

  if (override) {
    const iconSvg = noSanitize
      ? override
      : override
          ?.replace(/<style[^>]*>[\s\S]+<\/style>/gi, "")
          ?.replace(/<svg/gi, "$& fill='currentColor'");

    return (
      <span
        className={`${noSanitize ? "block no-sanitize icon" : "block icon"}${
          fitHeight ? "--fit-height" : ""
        } ${_className}`}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: iconSvg }}
      />
    );
  }
  if (foundIcon !== -1) {
    const iconSvg = _icons[foundIcon]?.svgCode;

    return (
      <span
        className={`block icon${fitHeight ? "--fit-height" : ""} ${_className}`}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: iconSvg }}
      />
    );
  }
  if (name) {
    // eslint-disable-next-line global-require
    const Content =
      // eslint-disable-next-line global-require
      require("@svg")[icons[name]] || require("@svg")[name]
        ? // eslint-disable-next-line global-require
          React.memo(require("@svg")[icons[name]] || require("@svg")[name])
        : null;
    return (
      <span
        className={`block icon${fitHeight ? "--fit-height" : ""} ${_className}`}
      >
        {Content && <Content />}
      </span>
    );
  }
  return null;
};

Icon.defaultProps = {
  fitHeight: false,
  className: "w-12 h-12",
  name: "name",
};

Icon.propTypes = {
  fitHeight: propTypes.bool,
  name: propTypes.string,
  className: propTypes.string,
};

export default Icon;
