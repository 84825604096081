import React from "react";
import classNames from "classnames";
import { Text, Image, Button } from "@atoms";
import { IconListItem } from "@molecules";

const CbBackground = ({
  image,
  heading,
  summary,
  textPosition,
  link,
  linkType,
  children,
  noMargin,
  textBottom,
  height,
  biggerText,
}) => {
  return (
    <section
      className={classNames(
        "min-h-10 flex flex-row relative text-white overflow-hidden",
        { "justify-end": textPosition === "right" },
        { "mt-2": !noMargin },
        { "lg:min-h-[48rem]": height === "tall" },
        { "lg:min-h-[38rem]": !height },
        { "lg:min-h-[24rem]": height === "short" }
      )}
    >
      <div
        className={classNames(
          "w-full h-full absolute inset-x-0 mx-auto z-0",
          {}
        )}
      >
        <Image {...image} fill />
      </div>

      <div className="w-full h-full absolute inset-0">
        <div
          className={classNames(
            "h-full w-2/3 absolute inset-y-0",
            { "bg-gradient-copy-on-left right-1/3": textPosition === "left" },
            { "bg-gradient-copy-on-right left-1/3": textPosition === "right" }
          )}
        />
      </div>

      <div
        className={classNames(
          "w-full md:w-1/2 py-16 flex z-10",
          { "justify-start md:justify-end": textPosition === "left" },
          { "justify-start": textPosition === "right" },
          { "items-center": !textBottom },
          { "items-end pt-44 pb-10 sm:pb-16 md:pb-40": textBottom }
        )}
      >
        <div className="px-4 md:px-16">
          <div className={classNames({ measure: !biggerText })}>
            <Text
              variant="h2"
              className={classNames({ "md:text-5xl": biggerText })}
            >
              {heading}
            </Text>
            <Text
              variant="body"
              className={classNames(
                "mt-10",
                { "mb-0": link?.url },
                { "mb-8": !link?.url }
              )}
            >
              {summary}
            </Text>
            {link?.url && (
              <div className="mt-8">
                <Button size="lg" to="/">
                  <Text>Testing</Text>
                </Button>
              </div>
            )}
            {children && (
              <ul className="mt-4">
                {children?.map((icon, i) => {
                  // eslint-disable-next-line react/no-array-index-key
                  return <IconListItem {...icon} key={i} />;
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

CbBackground.defaultProps = {};

CbBackground.propTypes = {};

export default CbBackground;
