import React from "react";
import classNames from "classnames";
import { Text, Container, Column } from "@atoms";

const CbIconGrid = ({ heading, subheading, lightGrayBackground, icons }) => {
  return (
    <section
      className={classNames("mt-2 text-dark-gray py-8 md:py-16", {
        "bg-light-gray": lightGrayBackground,
      })}
    >
      <Container variant="xl">
        <div className="text-center mb-12">
          <Text variant="h2" className="text-black">
            {heading}
          </Text>
          <Text
            variant="body"
            className="mt-6 text-dark-gray max-w-2xl mx-auto"
          >
            {subheading}
          </Text>
        </div>
        {icons?.length > 0 && (
          <div
            className={classNames(
              "flex items-start flex-wrap"
              // { "flex-nowrap xs:flex-wrap lg:flex-nowrap overflow-x-auto": !noHorizontalScroll },
              // { "flex-wrap": noHorizontalScroll },
              // { "justify-center" : center },
            )}
          >
            {icons?.map((column, i) => {
              // eslint-disable-next-line react/no-array-index-key
              return <Column {...column} key={i} />;
            })}
          </div>
        )}
      </Container>
    </section>
  );
};

CbIconGrid.defaultProps = {};

CbIconGrid.propTypes = {};

export default CbIconGrid;
