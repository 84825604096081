import React from "react";
import { Text, Icon, Container, Button } from "@atoms";
import { AppLink } from "@base";
import { t } from "@utils";
import { useAppState } from "@state/state";

const ReceiverResources = ({
  heading,
  subheading,
  pdfs,
  videos,
  articles,
  link,
}) => {
  const [{ translation }] = useAppState();
  const { lang } = translation;
  return (
    <section className="mt-2 py-16">
      <Container variant="lg" className="space-y-12 lg:space-y-16">
        <div className="mb-12">
          <Text variant="h2">{heading}</Text>
          <Text variant="body" className="mt-6 max-w-md">
            {subheading}
          </Text>
        </div>

        {!!pdfs?.length && (
          <div>
            <Text
              variant="h4"
              className="uppercase !font-bold tracking-wider text-dark-gray"
            >
              {t("PDF Downloads", lang)}
            </Text>
            <ul className="mt-6 lg:mt-8 space-y-4">
              {pdfs?.map((pdf, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={`pdf--${i}`}>
                    <AppLink
                      to={pdf?.url}
                      className="inline-flex flex-shrink-0 items-baseline"
                    >
                      <Icon
                        name="download"
                        className="w-6 h-6 text-blue fill-blue mr-3 lg:mr-4"
                        fitHeight
                      />
                      <Text variant="2xl" className="!font-heading">
                        {pdf?.text}
                      </Text>
                    </AppLink>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {!!videos?.length && (
          <div>
            <Text
              variant="h4"
              className="uppercase !font-bold tracking-wider text-dark-gray"
            >
              {t("Videos", lang)}
            </Text>
            <ul className="mt-6 lg:mt-8 space-y-4">
              {videos?.map((video, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li className="block" key={`video--${i}`}>
                    <AppLink
                      to={video?.url}
                      className="inline-flex flex-shrink-0 justify-start items-baseline"
                    >
                      <Icon
                        name="play"
                        className="w-6 h-6 text-blue fill-blue mr-3 lg:mr-4"
                        fitHeight
                      />
                      <Text variant="2xl" className="!font-heading">
                        {video?.text}
                      </Text>
                    </AppLink>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {!!articles?.length && (
          <div>
            <Text
              variant="h4"
              className="uppercase !font-bold tracking-wider text-dark-gray"
            >
              {t("Related Articles", lang)}
            </Text>
            <ul className="mt-6 lg:mt-8 space-y-4">
              {articles?.map((article, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={`video--${i}`}>
                    <AppLink
                      to={article?.url}
                      className="inline-flex flex-nowrap justify-start items-baseline"
                    >
                      <Icon
                        name="article"
                        className="w-7 h-7 text-blue fill-blue mr-3 lg:mr-4"
                        fitHeight
                      />
                      <div>
                        <Text variant="2xl" className="!inline !font-heading">
                          <span className="font-semibold mr-1">
                            {article?.category || "Article"}:
                          </span>
                          <span className="">{article?.title}</span>
                        </Text>
                      </div>
                    </AppLink>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {link?.url && (
          <div className="mt-6 lg:mt-8">
            <Button to={link?.url} arrow>
              {link?.text}
            </Button>
          </div>
        )}
      </Container>
    </section>
  );
};

ReceiverResources.defaultProps = {};

ReceiverResources.propTypes = {};

export default ReceiverResources;
