import React from "react";

const Divider = () => {
  return <div className="border-black my-6" />;
};

Divider.defaultProps = {};

Divider.propTypes = {};

export default Divider;
