import React from "react";
import classNames from "classnames";
import { Text, CbWrapper, Container } from "@atoms";

const CbBlockQuote = ({ copy, type, blog }) => {
  return (
    <Container variant="lg" interior={blog} padding={blog}>
      <CbWrapper type={type}>
        <div className={classNames("max-w-2xl", { "mx-auto": !blog })}>
          <Text
            variant={blog ? "lg" : null}
            className={classNames("", { "blog-text": blog })}
          >
            {copy}
          </Text>
        </div>
      </CbWrapper>
    </Container>
  );
};

CbBlockQuote.defaultProps = {};

CbBlockQuote.propTypes = {};

export default CbBlockQuote;
