/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { Icon, Container } from "@atoms";

const PreviewBanner = () => {
  const [{ layout }] = useAppState();
  const { previewMode } = layout;
  const [showing, setShowing] = useState();
  useEffect(() => {
    if (previewMode) {
      setShowing(true);
    }
  }, [previewMode]);

  return (
    <div
      className={classNames(
        "bg-blue text-white fixed bottom-0 left-0 right-0 p-6 transition duration-200 transform z-[999]",
        {
          "translate-y-full pointer-events-none opacity-0": !showing,
        }
      )}
    >
      <Container>
        <div className="flex items-center justify-between">
          <span className="mr-2 leading-normal">
            You are currently previewing unsaved changes. To publish these
            changes, make sure all required fields have a value and hit the
            "save" button.
          </span>
          <button
            onClick={() => setShowing(false)}
            type="button"
            className="ml-auto flex-shrink-0 opacity-50 hover:opacity-100 transition duration-200"
          >
            <Icon name="close" className="h-6 w-6" />
          </button>
        </div>
      </Container>
    </div>
  );
};

export default PreviewBanner;
